import React, { useState } from "react";
import ".././Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Form } from "reactstrap";
import { Link } from "react-router-dom";
const Downloads = ({ isOpen, onClose }) => {
  return (
    <div className={`login-modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <Form className="expire-model">
          <div
            className="titleBox d-flex flex-column"
            style={{ height: "150px" }}
          >
            <div className="closeBox">
              <span className="expire-close" onClick={onClose}>
                <FontAwesomeIcon icon={faClose} />
              </span>
            </div>
            <div className="rightBox rightBox-1" style={{ display: "block" }}>
              <Link
                to={
                  "https://apps.apple.com/in/app/citynect/id6503302420?platform=iphone"
                }
              >
                <span>
                  <img src="/emenities/Appstore.png" />
                </span>
                Appstore
              </Link>
              <Link
                to={
                  "https://play.google.com/store/apps/details?id=com.codingislife.citynect"
                }
              >
                {" "}
                <span>
                  <img src="/emenities/game.png" />
                </span>
                Playstore
              </Link>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Downloads;
