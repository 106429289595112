// import React, { useState } from "react";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-places-autocomplete";

// const LocationSearchInput = (props) => {
//   const { handleareaChange, area } = props;
//   const [address, setAddress] = useState(area);
//   const [showSuggestions, setShowSuggestions] = useState(false);

//   const handleChange = (address) => {
//     setAddress(address);
//     setShowSuggestions(!!address); // Show suggestions if there's any address
//   };

//   const handleSelect = async (address) => {
//     const results = await geocodeByAddress(address);
//     const selectedAddress = results[0].address_components[0].long_name;
//     setAddress(selectedAddress);
//     handleareaChange(selectedAddress);
//     setShowSuggestions(false); // Hide suggestions after selection
//   };

//   return (
//     <div className="area-box">
//       <PlacesAutocomplete
//         value={address}
//         onChange={handleChange}
//         onSelect={handleSelect}
//       >
//         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//           <div>
//             <input
//               {...getInputProps({
//                 placeholder: "Search Places ...",
//                 className: "location-search-input",
//               })}
//             />
//             {showSuggestions && !!address && ( // Only show if address is not empty
//               <div className="autocomplete-dropdown-containers">
//                 {loading && <div>Loading...</div>}
//                 {suggestions.map((suggestion) => {
//                   const className = suggestion.active
//                     ? "suggestion-item--active"
//                     : "suggestion-item";
//                   const style = suggestion.active
//                     ? { backgroundColor: "#fafafa", cursor: "pointer" }
//                     : { backgroundColor: "#ffffff", cursor: "pointer" };
//                   return (
//                     <div
//                       {...getSuggestionItemProps(suggestion, {
//                         className,
//                         style,
//                       })}
//                     >
//                       <span>{suggestion.description}</span>
//                     </div>
//                   );
//                 })}
//               </div>
//             )}
//           </div>
//         )}
//       </PlacesAutocomplete>
//     </div>
//   );
// };

// export default LocationSearchInput;

import {
  faLocationDot,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const LocationSearchInput = (props) => {
  const { handleareaChange, area, hideadd } = props;
  const [address, setAddress] = useState(area);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionRef = useRef(null); // Create a ref to store the selected suggestion

  const handleChange = (address) => {
    setAddress(address);
    setShowSuggestions(!!address); // Show suggestions if there's any address
  };

  const handleSelect = async (suggestion) => {
    const results = await geocodeByAddress(suggestion);
    const component = results[0].address_components.find(
      (component) =>
        component.types.includes("locality") ||
        component.types.includes("sublocality")
    );

    if (component) {
      const selectedAddress = component.long_name;
      setAddress(selectedAddress);
      suggestionRef.current = selectedAddress; // Store the selected address
      setShowSuggestions(false); // Hide suggestions after selection
    }
  };

  const handleAdd = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    if (suggestionRef.current) {
      handleareaChange(suggestionRef.current); // Use the stored address to handle area change
      setAddress("");
    }
  };

  return (
    <div className="area-box">
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          //           <div className="input-add-box">
          //              {/* <FontAwesomeIcon icon={faLocationDot} /> */}
          //             <input
          //               {...getInputProps({
          //                 placeholder: "Search Area ...",
          //                 className: hideadd == true ?("location-search-inputs"):("location-search-input"),
          //               })}
          //             />
          //             <button onClick={handleAdd} className="area-add-button">
          //               {hideadd ==true ?(
          //                <FontAwesomeIcon icon={faMagnifyingGlass} /> ):(
          //                 <svg
          //                   width="16"
          //                   height="16"
          //                   viewBox="0 0 16 16"
          //                   fill="none"
          //                   xmlns="http://www.w3.org/2000/svg"
          //                 >
          //                   <path
          //                     d="M6.84236 13.684C8.36051 13.6838 9.83493 13.1756 11.0307 12.2403L14.7906 16L16 14.7907L12.2401 11.031C13.1759 9.83514 13.6845 8.36046 13.6847 6.84199C13.6847 3.06949 10.6151 0 6.84236 0C3.06965 0 0 3.06949 0 6.84199C0 10.6145 3.06965 13.684 6.84236 13.684ZM6.84236 1.7105C9.67253 1.7105 11.9741 4.01197 11.9741 6.84199C11.9741 9.67201 9.67253 11.9735 6.84236 11.9735C4.01219 11.9735 1.71059 9.67201 1.71059 6.84199C1.71059 4.01197 4.01219 1.7105 6.84236 1.7105Z"
          //                     fill="white"
          //                   />
          //                 </svg>
          //  )}
          //             </button>

          <div className="input-add-box1">
            <input
              {...getInputProps({
                placeholder: "Search Area ...",
                className:
                  hideadd === true
                    ? "location-search-inputs"
                    : "location-search-input",
              })}
            />
            <button onClick={handleAdd} className="area-add-button">
              {hideadd === true ? (
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              ) : (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.84236 13.684C8.36051 13.6838 9.83493 13.1756 11.0307 12.2403L14.7906 16L16 14.7907L12.2401 11.031C13.1759 9.83514 13.6845 8.36046 13.6847 6.84199C13.6847 3.06949 10.6151 0 6.84236 0C3.06965 0 0 3.06949 0 6.84199C0 10.6145 3.06965 13.684 6.84236 13.684ZM6.84236 1.7105C9.67253 1.7105 11.9741 4.01197 11.9741 6.84199C11.9741 9.67201 9.67253 11.9735 6.84236 11.9735C4.01219 11.9735 1.71059 9.67201 1.71059 6.84199C1.71059 4.01197 4.01219 1.7105 6.84236 1.7105Z"
                    fill="white"
                  />
                </svg>
              )}
            </button>
            {showSuggestions &&
              !!address && ( // Only show if address is not empty
                <div
                  className={`autocomplete-dropdown-containers   ${
                    hideadd == true
                      ? "suggestion-containers"
                      : "suggestion-containers"
                  }`}
                >
                  {loading && <div>Loading...</div>}
                  {suggestions
                    .filter(
                      (suggestion) =>
                        suggestion.types.includes("locality") ||
                        suggestion.types.includes("sublocality")
                    )
                    .map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={suggestion.placeId} // Add a key for better list rendering
                          onClick={() => handleSelect(suggestion.description)} // Fill input on suggestion click
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                </div>
              )}
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default LocationSearchInput;
