import React, { useEffect, useState } from "react";
import "./Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import { Form, FormGroup, FormText } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "./shared/Loading";

const Login = ({ isOpen, onClose }) => {
  const [user, setUser] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  const sendOtp = async (e) => {
    setMinutes(1);
    setSeconds(30);
    e.preventDefault();
    setLoading(true);
    try {
      
      const response = await fetch(
        `${process.env.REACT_APP_API_IP}/user/login`,
        {
          method: "POST",
          body: JSON.stringify({ number: phoneNumber }), // Convert object to JSON string
          headers: {
            "Content-Type": "application/json", // Set the correct content type for JSON
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setUser(data); // Handle the API response here
        setOtpSent("success");
        setLoading(false);
        // console.log(data);
      } else {
        // console.error("Failed to submit property:", response.status);
        // setError("Number is not Registered");
        const error = await response.json();
        // console.log(error.error);
        setError(error.error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.error("Error submitting property:", error);
      setError("Internal Server Error");
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_IP}/user/verify-otp`,
        {
          method: "POST",
          body: JSON.stringify({ number: phoneNumber, otp: otp }), // Convert object to JSON string
          headers: {
            "Content-Type": "application/json", // Set the correct content type for JSON
          },
        }
      );
      if (response.ok) {
        localStorage.setItem("user", user.id);
        setLoading(false);
        window.location.reload();
      } else {
        // console.error(response.status);
        setError("Invalid Otp");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const resendOtp = async () => {
    setMinutes(1);
    setSeconds(30);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_IP}/user/resend-otp`,
        {
          method: "POST",
          body: JSON.stringify({ number: phoneNumber }), // Convert object to JSON string
          headers: {
            "Content-Type": "application/json", // Set the correct content type for JSON
          },
        }
      );
      if (response.ok) {
        setOtp("");
        setError(false);
        toast.success("OTP Resent Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Failed to submit property:", response.status);
      }
    } catch (error) {
      console.error("Error submitting property:", error);
    }
  };
  return (
    <div className={`login-modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <Form>
          <div className="titleBox d-flex flex-column">
            <div className="closeBox">
              <span className="close" onClick={onClose}>
                <FontAwesomeIcon icon={faClose} />
              </span>
            </div>
            <div className="d-flex justify-content-center">
              <h4>Welcome Back !</h4>
            </div>
            <span className="subheading">
              Hey, Enter Your details to get sign in to your account{" "}
            </span>
          </div>
          <div className="inputBox">
            <FormGroup>
              <Input
                type="number"
                id="phoneNumber"
                required
                placeholder="Phone"
                maxLength={10}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </FormGroup>
            {otpSent && (
              <FormGroup>
                <Input
                  type="number"
                  id="otp"
                  value={otp}
                  placeholder="OTP"
                  onChange={handleOtpChange}
                />
                <FormText className="formtext">
                  <div>
                    {seconds > 0 || minutes > 0 ? (
                       <span
                       role="button"
                       disabled={seconds > 0 || minutes > 0}
                       style={{
                         color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                       }}
                      //  onClick={resendOtp}
                       className="text-primary "
                     >
                       Send Again : {minutes < 10 ? `0${minutes}` : minutes}
                        :{seconds < 10 ? `0${seconds}` : seconds}
                     </span>
                     
                    ) : (
                      <p>Didn't recieve code?</p>
                    )}
                     {seconds > 0 || minutes > 0 ?(''): (
                    <span
                      role="button"
                      disabled={seconds > 0 || minutes > 0}
                      style={{
                        color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                      }}
                      onClick={resendOtp}
                      className="text-primary text-hover"
                    >
                      Send Again
                    </span>)}
                  </div>
                </FormText>
              </FormGroup>
            )}
            {error && <div className="text-danger">{error}</div>}
            {!otpSent && !loading ? (
              <button
                type="submit"
                className="mt-4 sm-mt-3"
                onClick={sendOtp}
              
              >
                <b> Send OTP</b>
              </button>
            ) : otpSent && !loading ? (
              <button className="mt-4" type="button" onClick={handleLogin}>
                Login
              </button>
            ) : loading ? (
              <div className="mt-3 text-center">
                <Loading />
              </div>
            ) : (
              <></>
            )}

            <div className="d-flex flex-row-reverse">
              <a
                href="https://wa.me/9316066832"
                className="float-right text-decoration-none text-dark"
                style={{ opacity: ".7", fontSize: "13px" }}
              >
                Need Help?
              </a>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
