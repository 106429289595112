import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

function PriorityDetails({ onSubmit, priorityDetail, setPriorityDetail }) {
  const [budget, setBudget] = useState(priorityDetail?.budget || "");
  const [location, setLocation] = useState(priorityDetail?.location || "");
  const [noOfPeople, setNoOfPeople] = useState(priorityDetail?.noOfPeople || 1);
  const [description, setDescription] = useState(
    priorityDetail?.description || ""
  );
  const submitHandler = (e) => {
    e.preventDefault();
    setPriorityDetail({ budget, location, noOfPeople, description });
    const data = {
      budget: budget,
      location,
      noOfPeople,
      description,
    };
    onSubmit(data);
  };
  return (
    <section>
      <div id="contactedPropertyHeading">
        <h4 className="mt-5">Add your requirements :</h4>
      </div>
      <div style={{ maxWidth: "100%" }}>
        <div
          className="row shadow-sm no-gutters rounded-2 px-2 py-4"
          id="propertyCard"
        >
          <Form onSubmit={submitHandler}>
            <FormGroup className="mb-3">
              <Label for="budget">Budget</Label>
              <Input
                id="budget"
                name="budget"
                placeholder="Please enter budget"
                type="number"
                min={0}
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="location">Location</Label>
              <Input
                id="location"
                name="location"
                placeholder="Please enter your preffered location"
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="noOfPeople">Number of People</Label>
              <Input
                id="noOfPeople"
                name="noOfPeople"
                type="select"
                value={noOfPeople}
                onChange={(e) => setNoOfPeople(e.target.value)}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </Input>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="description">Description</Label>
              <Input
                id="description"
                name="description"
                type="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>

            <Button outline className="rounded-pill px-4" color="primary">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </section>
  );
}

export default PriorityDetails;
