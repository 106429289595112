import React, { useEffect } from "react";
import usePayment from "../contexts/paymentContext";
import { PAYMENT_STATUS } from "../constants/payment";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CancelPayment = () => {
  const router = useNavigate();

  const cancelPaymentHandler = async () => {
    const orderId = localStorage.getItem('orderId')
    const response = await fetch(
      `${process.env.REACT_APP_API_IP}/api/v2/ccavenue/cancel-payment/${orderId}/dhbfuhf`,
      {
        method: "POST",
        body: JSON.stringify({ paymentStatus: "cancel" }),
        headers: { "Content-Type": "application/json" },
      }
    );
    const resp = await response.json()
    if (resp) {
      toast.success("Order cancelled successfully!", {
        position: "top-right",
        autoClose: 2000,
      });
      setTimeout(() => {
        router("/premium", { replace: true })
      }, 4000)
      return
    }
  }
  useEffect(() => {
    cancelPaymentHandler()
  }, [])

  return (
    <div className="checkoutBody">
      <main>
        <div className="checkoutCard">
          <div className="checkoutCard-body">
            <div className="checkoutCard-title">Payment Canceled</div>
            <span className="text-center">Your payment has been canceled. If you have any concerns, please reach out to our support team.</span>
            {/* <div className="checkoutCard-subtitle">Order Summary</div> */}
            <div className="checkoutCard-subtitle"></div>
            <div className="checkout-card-status-wrapper">
              <FontAwesomeIcon
                icon={faCircleXmark}
                size="6x"
                color={"red"}
              />
            </div>
            <div className="checkoutCard-text">
              {/* Payment {paymentStatus}, for order <strong>{getOrderId()}</strong> */}
            </div>
            <div className="checkoutCard-plan">
              <div className="d-flex">
                <span className="checkoutCard-plan-title" style={{ marginLeft: "30px" }}>Order ID:</span>
                <span className="checkoutCard-plan-price" style={{ marginLeft: "18px" }}>{localStorage.getItem('orderId')}</span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default CancelPayment