import React from "react";

const AboutUs = () => {
  return (
    <div className="terms-and-conditions my-5">
      <div className="page">
        <div className="content">
          <h1>About us</h1>
          <p>
            Welcome to Citynect, Ahmedabad's leading online platform for
            simplified housing solutions for bachelors and working
            professionals. We understand the challenges and frustrations that
            individuals face when searching for suitable accommodations in new
            cities, and our mission is to make this process easier, safer, and
            more efficient.
          </p>
          <p>
            At Citynect, we strive to connect users directly with verified
            property owners, eliminating the need for intermediaries and
            ensuring transparency throughout the rental process. Our
            user-friendly platform offers a wide range of housing options,
            including PGs (paying guest accommodations), flats, and shared
            apartments, tailored to meet the unique needs of bachelors and
            working professionals.
          </p>
          <p>
            With our extensive database of verified listings, users can easily
            explore and compare various properties, making informed decisions
            based on their preferences and budget. We prioritize the safety and
            comfort of our users by thoroughly vetting property owners and
            promoting a secure community environment.
          </p>
          <p>
            Our platform goes beyond just listings. We foster a vibrant
            community of users, providing opportunities for individuals to
            connect, share experiences, and find suitable roommates through our
            interactive features. Additionally, our dedicated support team is
            always available to assist users and ensure a seamless experience on
            our platform.
          </p>
          <p>
            Citynect is committed to simplifying the housing search process,
            saving users valuable time and effort. We believe in empowering
            individuals to find their ideal homes without the hassle of
            traditional methods. Whether you are a bachelor looking for a
            comfortable PG or a working professional seeking a shared flat,
            Citynect is here to connect you with the perfect housing solution.
          </p>
          <p>
            Join our growing community today and experience the convenience and
            reliability of Citynect. Your journey to finding the perfect
            accommodation starts here.
          </p>
          <p>Welcome to Citynect - Your Trusted Housing Search Partner.</p>
          <p>
            We are a registered company, Vocal Connect Pvt. Ltd., and Citynect
            is a subsidiary of Vocal Connect Pvt. Ltd. Our commitment to
            providing reliable and efficient services is reinforced by our
            established legal identity and dedication to our users
          </p>
          <p>
            For any query or help call us on : <strong>9316066832</strong>
          </p>
          <p>
            Or Send your query on : <strong>mail.citynect.in@gmail.com</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
