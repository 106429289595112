import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import "../ListProperty.css";
import Step2 from "./Demolist/step2.jsx";
import Step3 from "./Demolist/step3.jsx";
import Step4 from "./Demolist/step4.jsx";
// import LoginModal from "./Login.js";
import Signup from "../Signup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { Image } from "react-bootstrap";
// import { Link } from "react-router-dom";
const Demolist = ({ user }) => {
  const Navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  // const [isVerified, setisVerified] = useState();
  // const [loginmodal, setLoginmodal] = useState(false);
  const [signinmodal, setSigninmodal] = useState(false);
  // const signinModal = () => setSigninmodal(!signinmodal);

  const signinModal = () => {
    if (!user) {
      Navigate("/"); // Navigate to the home page when closing the modal without logging in
    } else {
      setSigninmodal(false);
    }
  };

  const todayDate = new Date().toISOString().substr(0, 10);

  const initialFlatSeekerDetails = {
    ownerId: "",
    name: "",
    number: "",
    type: "",
    flatType: "",
    preference: [],
    area: [],
    city: "",
    budget: "",
    furnishedType: [],
    occupancyType: [],
    profilePhoto: "",
    noOfPerson: 0,
    shiftingDate: "",
    gender: "",
    callAllowed: true,
    description: "",
  };

  const initialPropertyDetails = {
    ownerId: "",
    name: "",
    phone: "",
    tenantNumber: "",
    type: "",
    subtype: "",
    bedroom: "",
    furnishedType: "",
    amenities: [],
    memberedAllowed: "",
    nonVegAllowed: false,
    instructions: "",
    availableFrom: todayDate,
    washroomAttached: false,
    preference: [],
    sharingType: {
      singleSharing: "single",
      singleRent: "",
      doubleSharing: "double",
      doubleRent: "",
      tripleSharing: "triple",
      tripleRent: "",
    },
    rentEachHead: {
      singleSharing: "single",
      single: "",
      doubleSharing: "double",
      double: "",
      tripleSharing: "triple",
      triple: "",
    },
    totalFlatRent: "",
    isNegotiable: true,
    deposit: 1,
    noticePeriod: 1,
    address: {
      houseno: "",
      area: "",
      pincode: "",
      streetAddress: "",
      city: "",
    },
    description: "",
    callAllowed: true,
  };

  const [flatSeekerDetails, setFlatSeekerDetails] = useState(
    initialFlatSeekerDetails
  );
  const [propertyDetails, setPropertyDetails] = useState(
    initialPropertyDetails
  );
  console.log(propertyDetails);

  const resetDetails = (ownerId, type, name, number) => {
    setFlatSeekerDetails({
      ...initialFlatSeekerDetails,
      ownerId,
      type,
      name,
      number,
    });

    setPropertyDetails({
      ...initialPropertyDetails,
      ownerId,
      type,
      name,
      phone: number,
    });
  };

  const updateFlatSeekerDetails = (newDetails) => {
    setFlatSeekerDetails(newDetails);
  };

  const updatePropertyDetails = (newDetails) => {
    setPropertyDetails(newDetails);
  };

  const onChange = (value, field) => {
    if (field === "type") {
      resetDetails(
        propertyDetails.ownerId,
        value,
        propertyDetails.name,
        propertyDetails.phone
      );
    } else {
      setPropertyDetails((prevDetails) => ({
        ...prevDetails,
        [field]: value,
      }));
    }

    if (errors.name === field) {
      setErrors({
        name: "",
        error: "",
      });
    }
  };

  const handleInputChangeFlat = (e) => {
    const { name, value } = e.target;

    // Define the maximum length for fields that need restriction
    const maxLengthMap = {
      number: 10,
      // Add more fields as needed
    };

    // Check if the field needs length restriction
    if (
      maxLengthMap.hasOwnProperty(name) &&
      value.length > maxLengthMap[name]
    ) {
      // Truncate the value to the maximum length
      const truncatedValue = value.slice(0, maxLengthMap[name]);

      // Update the state with the truncated value
      setFlatSeekerDetails({
        ...flatSeekerDetails,
        [name]: truncatedValue,
      });
    } else {
      // For fields without length restriction, update the state normally
      setFlatSeekerDetails({
        ...flatSeekerDetails,
        [name]: value,
      });
    }
  };
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;

    // Define the maximum length for fields that need restriction
    const maxLengthMap = {
      rent: 6,
      deposit: 6,
      totalFlatRent: 6,
      // Add more fields as needed
    };

    // Validate input for the "name" field to only allow text
    if (name === "name") {
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setPropertyDetails({
          ...propertyDetails,
          [name]: value,
        });
        setErrors({
          ...errors,
          [name]: "",
        });
      } else {
        setErrors({
          ...errors,
          [name]: "Name should only contain letters.",
        });
      }
    } else {
      // Apply maxLength restriction if applicable
      if (maxLengthMap[name] && value.length > maxLengthMap[name]) {
        setErrors({
          ...errors,
          [name]: `Maximum length of ${maxLengthMap[name]} characters exceeded.`,
        });
      } else {
        setPropertyDetails({
          ...propertyDetails,
          [name]: value,
        });
        setErrors({
          ...errors,
          [name]: "",
        });
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Define the maximum length for fields that need restriction
    const maxLengthMap = {
      rent: 6,
      deposit: 6,
      totalFlatRent: 6,
      // Add more fields as needed
    };

    // Define the regular expression patterns for fields that require specific validation
    const validationRules = {
      description: /^[a-zA-Z.,\s]*$/, // Allow only letters, spaces, dots, and commas
      instructions: /^[a-zA-Z.,\s]*$/,

      // Add more fields with their respective regex patterns as needed
    };

    // Check if the field needs length restriction
    if (
      maxLengthMap.hasOwnProperty(name) &&
      value.length > maxLengthMap[name]
    ) {
      // Truncate the value to the maximum length
      const truncatedValue = value.slice(0, maxLengthMap[name]);

      // Update the state with the truncated value
      setPropertyDetails({
        ...propertyDetails,
        [name]: truncatedValue,
      });
      setFlatSeekerDetails({
        ...flatSeekerDetails,
        [name]: truncatedValue,
      });
    }
    // Check if the field requires specific validation
    else if (validationRules.hasOwnProperty(name)) {
      const regex = validationRules[name];

      // Check if the input matches the regex pattern or is an empty string
      if (regex.test(value) || value === "") {
        // If the input is valid, update the state
        setPropertyDetails({
          ...propertyDetails,
          [name]: value,
        });
        setFlatSeekerDetails({
          ...flatSeekerDetails,
          [name]: value,
        });
      }
    }
    // For fields without specific validation rules or length restriction, update the state normally
    else {
      setPropertyDetails({
        ...propertyDetails,
        [name]: value,
      });
      setFlatSeekerDetails({
        ...flatSeekerDetails,
        [name]: value,
      });
    }
  };

  const radioButtons = [
    { value: "pg", label: "PG" },
    { value: "sharing flat", label: "Sharing Flat" },
    { value: "private flat", label: "Private Flat" },
    { value: "flatseeker", label: "Flatseeker" },
  ];

  const [errors, setErrors] = useState({
    name: "",
    error: "",
  });
  const validateForm = () => {
    const newErrors = {};
    if (!propertyDetails.phone) {
      newErrors.name = "phone";
      newErrors.error = "Phone number is required.";
    } else if (propertyDetails.phone.length !== 10) {
      newErrors.name = "phone";
      newErrors.error = "Phone number must be 10 digits.";
    }
    if (!propertyDetails.name) {
      newErrors.name = "name";
      newErrors.error = "Owner name is required.";
    }

    if (!propertyDetails.type) {
      newErrors.name = "type";
      newErrors.error = "Please select a category.";
    }
    if (
      propertyDetails.type === "sharing flat" &&
      !propertyDetails.tenantNumber
    ) {
      newErrors.name = "tenantNumber";
      newErrors.error = "Please Enter Tenant number.";
    }
    if (
      propertyDetails.type === "sharing flat" &&
      propertyDetails.tenantNumber.length !== 10
    ) {
      newErrors.name = "tenantNumber";
      newErrors.error = "Tenant number must be 10 digits.";
    }
    // if (user.id == null) {
    //   newErrors.name = "id";
    //   newErrors.error = "Please signin again";
    // }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleNextClick = async () => {
    const isValid = validateForm();
    if (isValid) {
      if (propertyDetails.isVerified != "0") {
        const data = await axios.post(
          `${process.env.REACT_APP_API_IP}/user/check-user-account`,
          {
            number: propertyDetails.phone,
            name: propertyDetails.name, // Pass name here
          }
        );
        console.log(data.data.data.userId);
        setPropertyDetails({
          ...propertyDetails,
          ["ownerId"]: data.data.data.userId,
        });
        setFlatSeekerDetails({
          ...flatSeekerDetails,
          ["ownerId"]: data.data.data.userId,
        });
        if (data.data.data.isVerified === 1) {
          setCurrentStep(currentStep + 1);
        } else {
          setPropertyDetails({
            ...propertyDetails,
            ["isVerified"]: 0,
          });
          setFlatSeekerDetails({
            ...flatSeekerDetails,
            ["isVerified"]: 0,
          });
        }
      } else {
        const data = await axios
          .post(`${process.env.REACT_APP_API_IP}/user/verify-otp`, {
            number: propertyDetails.phone,
            otp: propertyDetails.otp,
            name: propertyDetails.name, // Pass name here
          })
          .then((res) => {
            console.log(res);
            if (res.data == "OTP verification is successful") {
              setCurrentStep(currentStep + 1);
            }
          })
          .catch((errors) => {
            console.log(errors.response.data.error);
            const newErrors = {};

            newErrors.name = "Otp";
            newErrors.error = errors.response.data.error;
            setErrors(newErrors);
          });
      }
    }
  };

  const handleSharingRentChange = (value, type) => {
    const maxLengthMap = {
      single: 6,
      double: 6,
      triple: 6,
    };

    // Check if the field needs length restriction
    if (
      maxLengthMap.hasOwnProperty(type) &&
      value.length > maxLengthMap[type]
    ) {
      // Truncate the value to the maximum length
      const truncatedValue = value.slice(0, maxLengthMap[type]);

      // Update the state with the truncated value
      setPropertyDetails({
        ...propertyDetails,
        sharingType: {
          ...propertyDetails.sharingType,
          [type]: truncatedValue,
        },
      });
    } else {
      // If the field does not need length restriction, update the state normally
      setPropertyDetails({
        ...propertyDetails,
        rentEachHead: {
          ...propertyDetails.rentEachHead,
          [type]: value,
        },
      });
    }
  };

  return (
    <div className="MainForListProperty" style={{ overflowX: "hidden" }}>
      {currentStep === 1 && (
        <div className="row justify-content-center">
          <div className="col-lg-6 all-margin">
            {/* <h3> Rent Your Property Now with just 3 Easy steps !!!</h3> */}
            {/* <div className="row align-items-center justify-content-center"> */}
            <div className="description-margin-top">
              <h2 className="text-lg-start text-justify1 list-introHeading">
                List Your Property with Ease in 3 Simple Steps!
              </h2>
              <p className="text-lg-start text-justify1 list-introText">
                Save <span style={{ color: "#00B090" }}>100%</span> Brokerage
                Fees
              </p>
              <div className="d-flex mx-2 flex-column justify-items-start ms-5 list-points">
                <p className="fw-normal col-md-10 my-0 mb-3" id="">
                  <svg
                    width="20"
                    height="13"
                    viewBox="0 0 20 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                      fill="#00B090"
                    />
                  </svg>{" "}
                  Create Your Property Listing
                </p>
                <p className="fw-normal col-md-10 my-0 mb-3" id="">
                  <svg
                    width="20"
                    height="13"
                    viewBox="0 0 20 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                      fill="#00B090"
                    />
                  </svg>{" "}
                  Get Direct Tenant Inquiries
                </p>
                <p className="fw-normal col-md-10 my-0 mb-3" id="">
                  <svg
                    width="20"
                    height="13"
                    viewBox="0 0 20 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                      fill="#00B090"
                    />
                  </svg>{" "}
                  Secure Property Details{" "}
                </p>
                <p className="fw-normal col-md-10 my-0 mb-3" id="">
                  <svg
                    width="20"
                    height="13"
                    viewBox="0 0 20 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                      fill="#00B090"
                    />
                  </svg>{" "}
                  Quick and Easy Access{" "}
                </p>
                <p className="fw-normal col-md-10 my-0 mb-3" id="">
                  <svg
                    width="20"
                    height="13"
                    viewBox="0 0 20 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                      fill="#00B090"
                    />
                  </svg>{" "}
                  No Additional Charges/Brokerage{" "}
                </p>
              </div>
              <p className="list-description d-none d-md-none d-lg-block">
                <strong>Please Note*</strong> We only accept listings directly
                from property owners or tenants (in case of preoccupied flats).
                Once you submit your listing, our team will verify the details.
                Upon successful verification, your listing will be live within
                an hour. If you have any queries or need assistance, please feel
                free to contact us at +91-9316066832.
              </p>
            </div>
          </div>
          <div className="col-lg-4 list-margin-top mb-3" id="formBox">
            <h3 className="mb-4 text-center">Let's start</h3>
            <Form>
              <FormGroup>
                <Label id="labelforMobileView" style={{ fontWeight: "500" }}>
                  Select category :
                </Label>
                <div className="radio-groups">
                  {radioButtons.map((button) => {
                    return (
                      <div
                        key={button.value}
                        className={`radio-button rounded-pill ${
                          propertyDetails.type === button.value
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => onChange(button.value, "type")}
                      >
                        {button.label}
                      </div>
                    );
                  })}
                </div>
                {errors.name === "type" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>

              
           
              <FormGroup>
                <Label id="labelforMobileView"> Owner Details :</Label>
                <Input
                  type="text"
                  value={propertyDetails.name}
                  onChange={handleInputTextChange}
                  name="name"
                  id="name"
                  placeholder="Owner Name"
                />
                {/* {errors.name && (
        <div className="error-message text-danger">
          {errors.name}
        </div>
      )} */}
              </FormGroup>

              

        

              {propertyDetails.type === "sharing flat" && (
                <FormGroup>
                  <Input
                    type="number"
                    value={propertyDetails.tenantNumber}
                    onChange={handleInputChange}
                    name="tenantNumber"
                    id="tenantNumber"
                    placeholder="Tenant Number"
                    maxLength={10}
                    minLength={10}
                  />
                  {/* to add icons before this  */}
                  {errors.name === "tenantNumber" && (
                    <div className="error-message text-danger">
                      {errors.error}
                    </div>
                  )}
                </FormGroup>
              )}
              <FormGroup>
                <Input
                  type="number"
                  value={propertyDetails.phone}
                  onChange={handleInputChange}
                  name="phone"
                  id="phone"
                  placeholder="Owner Phone Number"
                  maxLength={10}
                  minLength={10}
                />
                {/* to add icons before this  */}
                {errors.name === "phone" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>
              {propertyDetails.isVerified == "0" && (
                <FormGroup>
                  <Input
                    type="number"
                    value={propertyDetails.otp}
                    onChange={handleInputChange}
                    name="otp"
                    id="otp"
                    placeholder="OTP"
                    maxLength={10}
                    minLength={10}
                  />
                  {/* to add icons before this  */}
                  {errors.name === "Otp" && (
                    <div className="error-message text-danger">
                      {errors.error}
                    </div>
                  )}
                </FormGroup>
              )}
              <FormGroup>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#287DFD",
                    border: "none",
                    height: "40px",
                  }}
                  onClick={handleNextClick}
                >
                  Next <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </FormGroup>
            </Form>
          </div>
          <div className="d-lg-none d-md-block description-alert">
            {/* <div className="d-flex mx-2 flex-column justify-items-start ms-5">
              <p className="fw-normal col-md-10 my-0 mb-3" id="">
                <svg
                  width="20"
                  height="13"
                  viewBox="0 0 20 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                    fill="#00B090"
                  />
                </svg>{" "}
                Create Your Property Listing
              </p>
              <p className="fw-normal col-md-10 my-0 mb-3" id="">
                <svg
                  width="20"
                  height="13"
                  viewBox="0 0 20 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                    fill="#00B090"
                  />
                </svg>{" "}
                Get Direct Tenant Inquiries
              </p>
              <p className="fw-normal col-md-10 my-0 mb-3" id="">
                <svg
                  width="20"
                  height="13"
                  viewBox="0 0 20 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                    fill="#00B090"
                  />
                </svg>{" "}
                Secure Property Details{" "}
              </p>
              <p className="fw-normal col-md-10 my-0 mb-3" id="">
                <svg
                  width="20"
                  height="13"
                  viewBox="0 0 20 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                    fill="#00B090"
                  />
                </svg>{" "}
                Quick and Easy Access{" "}
              </p>
              <p className="fw-normal col-md-10 my-0 mb-3" id="">
                <svg
                  width="20"
                  height="13"
                  viewBox="0 0 20 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9982 2.5383C20.0007 2.67726 19.9698 2.81515 19.9076 2.94322C19.8453 3.07129 19.753 3.18674 19.6366 3.28225L10.2958 11.2056L8.54098 12.6935C8.4263 12.7928 8.28974 12.8711 8.13943 12.9237C7.98912 12.9764 7.82814 13.0022 7.66606 12.9998C7.50341 13.0027 7.34177 12.9771 7.19084 12.9244C7.03991 12.8718 6.90282 12.7933 6.78781 12.6935L5.03298 11.2056L0.361772 7.24322C0.245299 7.14772 0.153005 7.03227 0.0907498 6.90419C0.0284949 6.77612 -0.00236106 6.63823 0.000140839 6.49927C0.000140839 6.20747 0.120129 5.95901 0.361772 5.75533L2.1166 4.26744C2.23133 4.16769 2.3681 4.08903 2.51872 4.03616C2.66934 3.98329 2.83071 3.9573 2.99318 3.95974C3.33815 3.95974 3.62979 4.06231 3.87143 4.26744L7.66606 7.49602L16.1286 0.306463C16.3619 0.104225 16.6785 -0.00556118 17.0068 0.000217046C17.3501 0.000217046 17.6434 0.101336 17.8834 0.306463L19.6382 1.79436C19.8749 1.98793 20.0049 2.25806 19.9999 2.53975L19.9982 2.5383Z"
                    fill="#00B090"
                  />
                </svg>{" "}
                No Additional Charges/Brokerage{" "}
              </p>
            </div> */}
            <p className="list-description">
              <strong>Please Note*</strong> We only accept listings directly
              from property owners or tenants (in case of preoccupied flats).
              Once you submit your listing, our team will verify the details.
              Upon successful verification, your listing will be live within an
              hour. If you have any queries or need assistance, please feel free
              to contact us at +91-9316066832.
            </p>
          </div>
        </div>
      )}

      {currentStep === 2 &&
        propertyDetails.ownerId &&
        propertyDetails.type !== "flatseeker" && (
          <Step2
            propertyDetails={propertyDetails}
            updatePropertyDetails={updatePropertyDetails}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            handleInputChange={handleInputChange}
            handleSharingRentChange={handleSharingRentChange} // Add this line
          />
        )}
      {currentStep === 2 &&
        flatSeekerDetails.ownerId && flatSeekerDetails.name &&
        propertyDetails.type === "flatseeker" && (
          <Step3
            flatSeekerDetails={flatSeekerDetails}
            propertyDetails={propertyDetails}
            updateFlatSeekerDetails={updateFlatSeekerDetails}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            handleInputChange={handleInputChangeFlat}
          />
        )}
      {currentStep === 3 && propertyDetails.ownerId && (
        <Step4
          propertyDetails={propertyDetails}
          updatePropertyDetails={updatePropertyDetails}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleInputChange={handleInputChange}
        />
      )}
      {/* <LoginModal isOpen={loginmodal} onClose={loginModal} /> */}
      {/* <Signup isOpen={signinmodal} onClose={signinModal} /> */}
    </div>
  );
};

export default Demolist;
