import React from "react";

const RefundPolicy = () => {
  return (
    <div className="terms-and-conditions my-5">
      <div className="page">
        <div className="content">
          <h1>Refund Policy</h1>
          <p>
            At Citynect, we strive to provide the best possible experience for
            our users. We understand that occasionally, situations may arise
            where a refund is requested. To ensure clarity and transparency, we
            have outlined our refund policy below:
          </p>
          <p>
            <ol>
              <li>BASIC Plan</li>
              <p>The BASIC Plan subscription fee is non-refundable.</p>
              <li>King Plan and PRO Plan</li>
              <p>
                For our King Plan and PRO Plan subscribers, we offer a refund
                policy under specific circumstances. Users subscribing to these
                plans gain access to premium features, including direct contact
                with property owners.
              </p>
            </ol>
          </p>
          <p>
            <strong>Refund Eligibility: </strong>
            <br />A refund for the King Plan or PRO Plan is applicable under the
            following conditions:
          </p>
          <p>
            <ul>
              <li>
                The user has contacted our team due to an inability to find
                relevant properties on our platform.
              </li>
              <li>
                Despite our team's best efforts and within the promised
                timeframe of 10 days, no suitable leads have been provided to
                the user.
              </li>
            </ul>
          </p>
          <p>
            <strong>Refund Procedure: </strong>
            <br />
            If you believe you meet the above criteria for a refund, please
            contact our support team at <strong>9316066832 /</strong>  
            <strong> mail.citynect.in@gmail.com. </strong>Our team will
            investigate your case promptly and guide you through the refund
            process.
          </p>
          <p>
            <strong>Refund Timeframe: </strong>
            <br />
            Once approved, refunds for the King Plan and PRO Plan will be
            processed within 2-3 business days.
          </p>
          <p>
            <strong>**Contact Us:**</strong> <br />
            If you have any questions or concerns about our refund policy,
            please feel free to reach out to our support team at
            mail.citynect.in@gmail.com We are here to assist you.
          </p>
          <p>
            Thank you for choosing Citynect as your preferred accommodation
            solution. Your satisfaction is our top priority, and we are
            committed to ensuring a seamless experience for all our users.
          </p>
        </div>{" "}
      </div>
    </div>
  );
};

export default RefundPolicy;
