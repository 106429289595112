// import {
//   faCircleCheck,
//   faCircleXmark
// } from "@fortawesome/free-regular-svg-icons";
// import { faUser, faExclamation } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import React, { useEffect, useState } from "react";
// import { PAYMENT_STATUS } from "../../../constants/payment";
// import usePayment from "../../../contexts/paymentContext";
// import usePlan from "../../../contexts/planContext";
// import "../checkout.css";
// import { useNavigate } from "react-router-dom";
// import { Loading } from "../../shared/Loading";

// const CheckoutPage = ({ user }) => {
//   // const { paymentStatus, getOrderId } = usePayment();
//   const [paymentStatus, setPaymentStatus] = useState("")
//   const { plan } = usePlan();
//   const router = useNavigate();
//   let count = 0

//   const getStatus = async () => {
//     count = count + 1
//     const orderId = localStorage.getItem('orderId')
//     const response = await fetch(`${process.env.REACT_APP_API_IP}/api/v2/ccavenue/payment-Status/${orderId}`);
//     const jsonResp = await response.json()
//     setPaymentStatus(jsonResp.paymentStatus)
//     if (jsonResp.paymentStatus === 'Processing') {
//       router("/payment-status?status=processing", { replace: true });
//     } else if (jsonResp.paymentStatus === 'Failed') {
//       router("/payment-status?status=fail", { replace: true });
//     } else if (jsonResp.paymentStatus === 'Success') {
//       router("/payment-status?status=success", { replace: true });
//     }
//   }

//   useEffect(() => {
//     router("/payment-status?status=processing", { replace: true });
//     getStatus()
//   }, []);

//   return (
//     <div className="checkoutBody">
//       <main>
//         <div className="checkoutCard">
//           <div className="checkoutCard-body">
//             <div className="checkoutCard-title">
//               {paymentStatus === PAYMENT_STATUS.SUCCESS
//                 ? "Payment Successful" :
//                 paymentStatus === PAYMENT_STATUS.FAILURE
//                   ? "Payment Failed"
//                   : "Payment Processing"
//               }
//             </div>
//             <span className="text-center">{
//               paymentStatus === PAYMENT_STATUS.SUCCESS
//                 ? "Your payment has been successfully processed. Thank you for choosing our services." :
//                 paymentStatus === PAYMENT_STATUS.FAILURE
//                   ? "Oops! Something went wrong. Please check your details and try again."
//                   : "Your payment is currently being processed. If it takes more than 5 minutes, kindly contact our support team."
//             }</span>
//             <div className="checkoutCard-subtitle">Order Summary</div>
//             <div className="checkout-card-status-wrapper">
//               <FontAwesomeIcon
//                 icon={
//                   paymentStatus === PAYMENT_STATUS.SUCCESS
//                     ? faCircleCheck :
//                     paymentStatus === PAYMENT_STATUS.FAILURE
//                       ? faCircleXmark : faExclamation
//                 }
//                 size="6x"
//                 color={
//                   paymentStatus === PAYMENT_STATUS.SUCCESS ? "green" :
//                     paymentStatus === PAYMENT_STATUS.FAILURE
//                       ? "red" : "#ffc107"
//                 }
//               />
//             </div>
//             <div className="checkoutCard-text">
//               Payment {paymentStatus}, for order <strong>{localStorage.getItem('orderId')}</strong>
//             </div>
//             <div className="checkoutCard-plan">
//               <div className="checkoutCard-plan-img">
//                 <FontAwesomeIcon icon={faUser} />
//               </div>
//               <div className="checkoutCard-plan-text">
//                 <div className="checkoutCard-plan-title">{plan.title}</div>
//                 <div className="checkoutCard-plan-price">Rs {plan.price}</div>
//               </div>
//             </div>
//             <div className="checkoutCard-payment-button">
//               {
//                 paymentStatus === PAYMENT_STATUS.SUCCESS ? <button onClick={() => router("/myaccount/premiumdetails", { replace: true })}>See plan details</button> :
//                   paymentStatus === PAYMENT_STATUS.FAILURE ? <button onClick={() => router("/premium", { replace: true })}>Retry</button> :
//                     paymentStatus === PAYMENT_STATUS.PROCESSING ? <button onClick={() => window.open("http://wa.me/+919328254367", '_blank')}>Help</button> :
//                       <div className="mt-3 text-center">
//                         <Loading />
//                       </div>
//               }
//             </div>
//           </div>
//         </div>
//       </main>
//     </div>
//   );
// };

// export default CheckoutPage;

import {
  faCircleCheck,
  faCircleXmark
} from "@fortawesome/free-regular-svg-icons";
import { faUser, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { PAYMENT_STATUS } from "../../../constants/payment";
import usePayment from "../../../contexts/paymentContext";
import usePlan from "../../../contexts/planContext";
import "../checkout.css";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../shared/Loading";

const CheckoutPage = ({ user }) => {
  // const { paymentStatus, getOrderId } = usePayment();
  const [paymentStatus, setPaymentStatus] = useState("")
  const { plan } = usePlan();
  const router = useNavigate();

  const getStatus = async () => {
    const orderId = localStorage.getItem('orderId')
    const response = await fetch(`${process.env.REACT_APP_API_IP}/api/v2/ccavenue/payment-Status/${orderId}/efjiehfue`);
    const jsonResp = await response.json()
    setPaymentStatus(jsonResp.paymentStatus)
    if (jsonResp.paymentStatus === 'Processing') {
      router("/payment-status?status=processing", { replace: true });
    } else if (jsonResp.paymentStatus === 'Failed') {
      router("/payment-status?status=fail", { replace: true });
    } else if (jsonResp.paymentStatus === 'Success') {
      router("/payment-status?status=success", { replace: true });
    }
  }
  const userOrderId = localStorage.getItem('orderId')

  useEffect(() => {
    router("/payment-status?status=processing", { replace: true });
    getStatus()
  }, []);

  return (
    <div className="checkoutBody">
      <main>
        <div className="checkoutCard">
          <div className="checkoutCard-body">
            <div className="checkoutCard-title">
              {paymentStatus === PAYMENT_STATUS.SUCCESS
                ? "Payment Successful" :
                paymentStatus === PAYMENT_STATUS.FAILURE
                  ? "Payment Failed"
                  : "Payment Processing"
              }
            </div>
            <span className="text-center">{
              paymentStatus === PAYMENT_STATUS.SUCCESS
                ? "Your payment has been successfully processed. Thank you for choosing our services." :
                paymentStatus === PAYMENT_STATUS.FAILURE
                  ? "Oops! Something went wrong. Please check your details and try again."
                  : "Your payment is currently being processed. If it takes more than 5 minutes, kindly contact our support team."
            }</span>
            <div className="checkoutCard-subtitle">Order Summary</div>
            <div className="checkout-card-status-wrapper">
              <FontAwesomeIcon
                icon={
                  paymentStatus === PAYMENT_STATUS.SUCCESS
                    ? faCircleCheck :
                    paymentStatus === PAYMENT_STATUS.FAILURE
                      ? faCircleXmark : faExclamation
                }
                size="6x"
                color={
                  paymentStatus === PAYMENT_STATUS.SUCCESS ? "green" :
                    paymentStatus === PAYMENT_STATUS.FAILURE
                      ? "red" : "#ffc107"
                }
              />
            </div>
            <div className="checkoutCard-text">
              Payment {paymentStatus}, for order <strong>{localStorage.getItem('orderId')}</strong>
            </div>
            <div className="checkoutCard-plan">
              <div className="checkoutCard-plan-img">
                <FontAwesomeIcon icon={faUser} />
              </div>
              <div className="checkoutCard-plan-text">
                <div className="checkoutCard-plan-title">{plan.title}</div>
                <div className="checkoutCard-plan-price">Rs {plan.price}</div>
              </div>
            </div>
            <div className="checkoutCard-payment-button">
              {
                paymentStatus === PAYMENT_STATUS.SUCCESS ? <button onClick={() => router("/myaccount/premiumdetails", { replace: true })}>See plan details</button> :
                  paymentStatus === PAYMENT_STATUS.FAILURE ? <button onClick={() => router("/premium", { replace: true })}>Retry</button> :
                    paymentStatus === PAYMENT_STATUS.PROCESSING ? <button onClick={() => window.open("https://api.whatsapp.com/send?phone=+919316066832&text=Please%20check%20my%20order%20OrderId:%20" + userOrderId, '_blank')}>Help</button> :
                      <div className="mt-3 text-center">
                        <Loading />
                      </div>
              }
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CheckoutPage;