import "./PayingGuest.css";
import { FormGroup, Form, Modal } from "react-bootstrap";
import MultiRangeSlider from "multi-range-slider-react";

import { React, useEffect, useState } from "react";
import LoginModal from "./Login.js";
import Signup from "./Signup.js";
// import CustomPopup from "./Others/CustomPopup.js";
// import CustomWhatsappPopup from "./Others/CustomWhatsappPopup.js";
import ExpirePopup from "./Others/ExpirePopup.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NearbyLocations from "./NearbyLocations.jsx";
import Select from "react-select";
import ParticularProperty from "./ParticularFlatseekerProperty.jsx";

import {
  faHeart as fasolidHeart,
  faEllipsisVertical,
  faLocationDot,
  faMagnifyingGlass,
  faFilter,
  faArrowRight,
  faArrowLeft,
  faClose,
  faPhone,
  faComment,
  faPuzzlePiece,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart,
  faShareFromSquare,
} from "@fortawesome/free-regular-svg-icons";
import { Link, redirect, useNavigate, useLocation } from "react-router-dom";
import { Input, Label, Button } from "reactstrap";
import MapContainer from "./Others/Map";
import CustomCarousel from "./Others/CustomCarousel";
import axios from "axios";
import Downloads from "./Others/Downloadmodal.jsx";
import { debounce } from "lodash";
const FlatSeekers = (props) => {
  const { user } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState(properties);
  const [locationFilter, setLocationFilter] = useState("");
  const [shiftingDate, setshiftingDate] = useState("");
  const [areaFilter, setareaFilter] = useState("");
  const [area, setarea] = useState([]);
  const [budgetFilter, setBudgetFilter] = useState(50000);
  const [selectedPropertyID, setSelectedPropertyID] = useState(null);

  const [genderFilter, setGenderFilter] = useState("");
  const [occupancyFilter, setOccupancyFilter] = useState("");
  const [furnishedFilter, setFurnishedFilter] = useState("");
  const [availabilityFilter, setAvailabilityFilter] = useState("");
  const [preference, setpreference] = useState("");

  const [minValue, set_minValue] = useState(2000);
  const [maxValue, set_maxValue] = useState(50000);
  const [preferencefilter, setpreferencefilter] = useState("");
  const [cityFilter, setcityFilter] = useState("");
  const [city, setcity] = useState("");
  const [totalItems, settotalItems] = useState(0);
  const [totalPages, settotalPages] = useState(0);
  const [selecttype, setselecttype] = useState([
    { value: "pg" },
    { value: "sharing flat" },
    { value: "private flat" },
  ]);
  const [type, settype] = useState();
  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  const userAgent = navigator.userAgent;
  // alert(userAgent);
  const osType = (() => {
    if (userAgent.includes("Windows")) {
      return "Windows";
    } else if (userAgent.includes("Mac")) {
      return "Mac OS";
    } else if (userAgent.includes("Android")) {
      return "Android";
    } else {
      return "Unknown";
    }
  })();

  // alert("Operating System:", osType);
  const [downloadmodel, setdownloadmodel] = useState(false);
  const [expirePopupmodal, setExpirePopupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // State to store selected options
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setSelectedPropertyID(null); // Reset selectedPropertyID to close the modal
    const params = new URLSearchParams(location.search);
    params.delete("propertyId");
    navigate({ pathname: location.pathname, search: params.toString() });
  };

  const [preferencesFilter, setPreferencesFilter] = useState("");

  const Preferences = [
    { value: "Vegetarian", label: "Vegetarian" },
    { value: "Night Owl ", label: "Night Owl " },
    { value: "Early Riser", label: "Early Riser" },
    { value: "Foodie", label: "Foodie" },
    { value: "Social Butterfly", label: "Social Butterfly" },
    { value: "Alcohol Enthusiast", label: "Alcohol Enthusiast" },
    { value: "Pet Lover", label: "Pet Lover" },
    { value: "Health Conscious", label: "Health Conscious" },
    { value: "Adventure Seeker", label: "Adventure Seeker" },
    { value: "Smoking", label: "Smoking" },
  ];

  const resetfilter = () => {
    setcity("");
    setarea("");
    settype("");
    // setsubtype("");
    // setBedroomFilter("");
    setFurnishedFilter("");
    setFurnishedFilter("");
    setAvailabilityFilter("");
    // const newOccupancyFilter = [...occupancyFilter];
    // newOccupancyFilter[1].iselected = "false";
    // setOccupancyFilter(newOccupancyFilter);
    // newOccupancyFilter[2].iselected = "false";
    // setOccupancyFilter(newOccupancyFilter);
    // newOccupancyFilter[0].iselected = "false";
    // setOccupancyFilter(newOccupancyFilter);
    setGenderFilter("");
    setPreferencesFilter("");
    setshiftingDate("");
    //
  };

  const handlePreferencesChange = (value) => {
    if (preferencesFilter.includes(value)) {
      setPreferencesFilter(preferencesFilter.filter((pref) => pref !== value));
    } else {
      setPreferencesFilter([...preferencesFilter, value]);
    }
  };

  const handleShow = (propertyID) => {
    setSelectedPropertyID(propertyID);
    const params = new URLSearchParams(location.search);
    params.set("propertyId", propertyID);
    navigate({ pathname: location.pathname, search: params.toString() });
  };

  const applyFilters = async (currentpg) => {
    setIsLoading(true);
    if (!currentpg) {
      setCurrentPage(0);
    }
    let page = currentpg || 0;
    let value = {
      type: type,
      city: city !== "" ? city && city : "",
      areas: area !== "" ? area && area : [],
      furnishedType: furnishedFilter !== "" ? [furnishedFilter] : [],
      availableFrom: availabilityFilter !== "" ? availabilityFilter : [],
      occupancyType: occupancyFilter !== "" ? occupancyFilter : [],
      gender: genderFilter !== "" ? genderFilter : "",
      preference: preferencesFilter.length > 0 ? preferencesFilter : [],
      shiftingDate: shiftingDate !== "" ? shiftingDate : "",
      budget: budgetFilter !== "" ? budgetFilter : "",
    };
    let headers = {
      "Content-Type": "application/json",
    };
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_IP}/v2/cjebchu/website/ckijevcu/flatseekers/filter?page=${page}&size=10`,
        value,
        headers
      );
      setIsLoading(false);

      setProperties(data.data.data.properties);
      setFilteredProperties(data.data.data.properties);
      settotalItems(data.data.data.totalItems);
      settotalPages(data.data.data.totalPages);
    } catch (error) {
      console.error("Error fetching filtered properties:", error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const propertyId = params.get("propertyId");
    if (propertyId) {
      setSelectedPropertyID(propertyId);
    }
  }, [location.search]);

  // Handler function to update selected options
  const debouncedApplyFilters = debounce(applyFilters, 300);
  useEffect(() => {
    debouncedApplyFilters();
  }, [
    city,
    area,
    type,
    furnishedFilter,
    availabilityFilter,
    occupancyFilter,
    genderFilter,
    preferencesFilter,
    shiftingDate,
  ]);

  // const customPopupModal = () => {
  //   if (customPopupmodal) {
  //     window.location.reload();
  //   }
  //   setCustomPopupmodal(!customPopupmodal);
  // };
  const ExpirePopupModal = () => {
    if (expirePopupmodal) {
      window.location.href = "/premium";
    }
    setExpirePopupModal(!expirePopupmodal);
  };
  // const customwhatsappPopupModal = (phone) => {
  //   if (customWhatsappPopupmodal) {
  //     if (typeof phone === "string") {
  //       window.location.href = `https://wa.me/${phone}`;
  //     } else {
  //       console.error("Invalid phone number format");
  //     }
  //   }
  //   setCustomWhatsappPopupmodal(!customWhatsappPopupmodal);
  // };

  const handleLocationChange = (value) => {
    if (value === city) {
      setcity("");
    } else {
      setcity(value);
    }
    // applyFilters();
  };
  const handledateChange = (e) => {
    function formatDate(inputDate) {
      const parts = inputDate.split("-");
      if (parts.length === 3) {
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
      }
      return inputDate;
    }
    setshiftingDate(formatDate(e.target.value));
    // applyFilters();
  };
  const handleareaChange = (value) => {
    setarea([...area, value]);
  };
  const handlePrefrenceChange = (value) => {
    setpreference(value);
  };
  const handleBudgetChange = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      const value = parseInt(event.target.value.trim());
      setBudgetFilter(value);
      applyFilters();
    }
  };
  const handleGenderChange = (value) => {
    setGenderFilter(value);
    if (value === genderFilter) {
      setGenderFilter("");
    } else {
      setGenderFilter(value);
    }
    // applyFilters();
  };
  const handleOccupancyChange = (value) => {
    if (value === occupancyFilter[0]) {
      setOccupancyFilter("");
    } else {
      setOccupancyFilter([value]);
    }
    // applyFilters();
  };
  const handleFurnishedChange = (value) => {
    setFurnishedFilter(value);
    // applyFilters();
  };
  const handleAvailabilityChange = (value) => {
    if (value === availabilityFilter) {
      setAvailabilityFilter("");
    } else {
      setAvailabilityFilter(value);
    }
    // applyFilters();
  };
  const CITY_STORAGE_KEY = "active-city-data";

  const getcity = async () => {
    // Check for stored data first
    const storedData = localStorage.getItem(CITY_STORAGE_KEY);

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        // Optional: Check for data validity (e.g., expiration time) here
        setcityFilter(parsedData);
        return; // No need to fetch if valid data exists in storage
      } catch (error) {
        console.error("Error parsing stored active city data:", error);
        // Remove potentially corrupt data
        localStorage.removeItem(CITY_STORAGE_KEY);
      }
    }

    // Fallback to API call if not found or invalid
    const apiResponse = await axios.get(
      `${process.env.REACT_APP_API_IP}/v2/cjebchu/website/ckijevcu/active-city`
    );
    setcityFilter(apiResponse.data);
    localStorage.setItem(CITY_STORAGE_KEY, JSON.stringify(apiResponse.data));
  };

  const debouncedGetCity = debounce(getcity, 300);
  useEffect(() => {
    debouncedGetCity();
  }, []);

  const [listView, setListView] = useState(false);
  const handleToggle = () => {
    setListView(!listView);
  };

  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "", label: "Both" },
  ];
  const Occupancy = [
    { value: "Single", label: "Single" },
    { value: "Double", label: "Double" },
    { value: "Triple", label: "Triple" },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  let visibleData = filteredProperties;
  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      let pgs = currentPage - 1;
      applyFilters(pgs);
    } else {
      alert("this is first page");
    }
    window.scrollTo(0, 0);
  };
  const goToNextPage = () => {
    let pgs = currentPage + 1;
    if (pgs < totalPages) {
      setCurrentPage(currentPage + 1);
      applyFilters(currentPage + 1);
    } else {
      alert("this is Last page");
    }
    window.scrollTo(0, 0);
  };
  const handleShareClick = async (datas) => {
    console.log(datas);
    try {
      if (navigator.share) {
        await navigator.share({
          text: `
                Hey, ${datas.name} is looking for a ${datas.type}🏡
                Gender: ${datas.gender}
                📍 Preferred Locations: ${datas.area.map((item, index) => item)}
                 ₹ Budget: ${datas.budget}
                -> Check out more details on the Citynect app:
                https://play.google.com/store/apps/details?id=com.codingislife.citynect
                citynect - Simplifying Bachelor Housing Search [Flat & Flatmates India]`,
        });
      } else {
        // Fallback for browsers that don't support the Web Share API
        console.log("Web Share API is not supported.");
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };
  const [addresses, setAddresses] = useState();
  useEffect(() => {
    const addresses = filteredProperties
      .map((property) => {
        if (
          property.address &&
          property.address.latitude &&
          property.address.longitude
        ) {
          return {
            label: property.title,
            link: `/particular-Flatseeker/${property.id}`,
            coordinates: [
              parseFloat(property.address.latitude),
              parseFloat(property.address.longitude),
            ],
          };
        } else {
          // Handle case where address or latitude/longitude is missing
          // For example, you can return null or an empty object
          return null; // or return {} or any other suitable default value
        }
      })
      .filter((address) => address !== null);
    setAddresses(addresses);
  }, [filteredProperties]);
  const contactOwner = async (id) => {
    if (!user) {
      signinModal();
    } else if (!user.isVerified) {
      signinModal();
    } else {
      if (user.propertyCount > 0) {
        // setCustomPopupData(user.propertyCount);
        // api to reduce the count for property details
        try {
          const data = {
            id: user.id,
            propertyId: id,
          };
          const response = await fetch(
            `${process.env.REACT_APP_API_IP}/property/contacted`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
          );
          if (response.ok) {
            // if (!user.isPremium) {
            //   customPopupModal();
            // } else {
            //   window.location.reload();
            // }
            window.location.reload();
          } else {
            console.error("Failed to submit property:", response.status);
          }
        } catch (error) {
          console.error("Error submitting property:", error);
        }
      } else {
        ExpirePopupModal();

        // alert("Please Buy Premium");
      }
    }
  };

  const [loginmodal, setLoginmodal] = useState(false);
  const [filterBar, setFilterBar] = useState();
  const filterModal = () => setFilterBar(!filterBar);
  const [signinmodal, setSigninmodal] = useState(false);
  const loginModal = () => setLoginmodal(!loginmodal);
  const signinModal = () => setSigninmodal(!signinmodal);
  const [showReportButtonForProperty, setShowReportButtonForProperty] =
    useState(null);

  const handleMouseEnter = (propertyId) => {
    setShowReportButtonForProperty(propertyId);
  };

  const handleMouseLeave = () => {
    setShowReportButtonForProperty(null);
  };
  const handletypechange = (value) => {
    if (value == type) {
      settype("");
    } else {
      settype(value);
    }
  };

  const navi = useNavigate();
  const downloadlink = () => {
    // if (osType ==-
    if (osType == "Android") {
      window.open(
        "https://play.google.com/store/apps/details?id=com.codingislife.citynect",
        "_blank",
        "rel=noopener noreferrer"
      );
    } else if (osType == "Mac OS") {
      window.open(
        "https://apps.apple.com/",
        "_blank",
        "rel=noopener noreferrer"
      );
    } else {
      setdownloadmodel(true);
    }
  };
  const removearea = (items) => {
    const data = area.filter((item) => items != item);
    setarea(data);
  };
  return (
    <div className="main">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <div className="header">
        {/* Search Bar */}
        <h6 className="resultCount">
          Search Result for properties in "
          {locationFilter === "" ? city : locationFilter}" {totalItems} Result
          found{" "}
        </h6>
      </div>
      <div className="text-center mt-3 extra-header">
        <h6 className="resultCount">{totalItems} Results found </h6>
      </div>
      <div id="mobileviewHeader">
        <div className="search-sortBar">
          <div className="mobilview-search-bar shadow-sm  rounded-pill">
            <div className="mobileview-search-icon">
              {/* <FontAwesomeIcon icon={faLocationDot} /> */}
            </div>
          </div>
          <div className="sortButton rounded" onClick={filterModal}>
            <FontAwesomeIcon icon={faFilter} />
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        {/* Filter Bar */}
        <div className=" col-md-3" id="filterBar">
          <Form>
            <FormGroup className="mt-3">
              <Label for="location">Location :</Label>
              {/* <Input
                id="location"
                placeholder="search..."
                value={city}
                onChange={(e) => {
                  handleLocationChange(e.target.value);
                }}
              /> */}
              <div className="location-groups mt-2">
                {cityFilter &&
                  cityFilter.map((button) => (
                    <div
                      key={button.cityName}
                      className={`location-button rounded-pill ${
                        city === button.cityName ? "selected" : ""
                      }`}
                      value={city}
                      onClick={() => {
                        handleLocationChange(button.cityName);
                      }}
                    >
                      {button.cityName.charAt(0).toUpperCase() +
                        button.cityName.slice(1)}
                    </div>
                  ))}
              </div>
            </FormGroup>
            <FormGroup className="mt-3">
              <Label for="location">Area :</Label>
              <NearbyLocations
                handleareaChange={handleareaChange}
                area={area}
              />
              <div className="location-groups">
                {area &&
                  area.map((item, index) => {
                    return (
                      <div
                        m
                        key={index}
                        className={`location-button rounded-pill selected mt-1`}
                        onClick={() => {
                          removearea(item);
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
              </div>
            </FormGroup>
            <FormGroup className="mt-3">
              <Label for="location">Type :</Label>

              <div className="location-groups mt-2">
                {selecttype &&
                  selecttype.map((button) => {
                    return (
                      <div
                        key={button.value}
                        className={`location-button rounded-pill ${
                          type === button.value ? "selected" : ""
                        }`}
                        value={type}
                        onClick={() => {
                          handletypechange(button.value);
                        }}
                      >
                        {button.value}
                      </div>
                    );
                  })}
              </div>
            </FormGroup>
            <FormGroup className="mt-3">
              <Label for="budget">Budget :</Label>
              <div className="range-bar" style={{ position: "relative" }}>
                <input
                  type="number"
                  className="range-input"
                  min="5000"
                  max="50000"
                  value={budgetFilter}
                  step="1000"
                  onChange={(e) => setBudgetFilter(e.target.value)}
                  onKeyPress={handleBudgetChange}
                />
              </div>
            </FormGroup>
            <FormGroup className="mt-3">
              <Label>Gender :</Label>
              <div className="location-groups">
                {gender.map((button) => (
                  <div
                    key={button.value}
                    className={`location-button rounded-pill ${
                      genderFilter === button.value ? "selected" : ""
                    }`}
                    value={genderFilter}
                    onClick={() => {
                      handleGenderChange(button.value);
                    }}
                  >
                    {button.label}
                  </div>
                ))}
              </div>
            </FormGroup>
            <FormGroup className="mt-3">
              <Label for="location">Shifting :</Label>
              <Input
                type="date"
                id="location"
                placeholder="search..."
                // value={area}
                onChange={(e) => {
                  handledateChange(e);
                }}
              />
            </FormGroup>

            <FormGroup className="mt-3">
              <Label>Preferences :</Label>
              <div className="location-groups">
                {Preferences.map((button) => (
                  <div
                    key={button.value}
                    className={`location-button rounded-pill ${
                      preferencesFilter.includes(button.value) ? "selected" : ""
                    }`}
                    onClick={() => {
                      handlePreferencesChange(button.value);
                    }}
                  >
                    {button.label}
                  </div>
                ))}
              </div>
            </FormGroup>
            <FormGroup className="mt-3">
              <div className="location-groups">
                <input
                  type="Button"
                  className="form-control btn btn-primary"
                  value={"Reset Filter"}
                  onClick={resetfilter}
                />
              </div>
            </FormGroup>
          </Form>
        </div>
        {/* Main Contain */}
        <div className="mainContain col-md-8 d-none d-md-block">
          {!listView ? (
            <div className="mb-3" style={{ maxWidth: "100%" }}>
              {isLoading ? ( // Check if data is loading
                <div className="text-center fs-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : visibleData.length === 0 ? (
                <div className="text-center fs-4">No Result Found</div>
              ) : (
                <div
                  className="property-grid grid-gap flatseeker-card"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  {visibleData.map((property, index) => {
                    return (
                      <div
                        className="property-card  row  "
                        key={index}
                        id="propertyCard"
                        style={{
                          display: "flex",
                          padding: "15px",
                          marginBottom: "0px",
                        }}
                      >
                        {/* Property Image Section */}

                        <div className="card-upper">
                          <div
                            className="property-image property-new-image"
                            style={{ padding: "0px" }}
                          >
                            {/* <span className="gender">{property.gender}</span> */}
                            <img
                              className="propertyImage"
                              id="CarouselImage"
                              src={property.profilePhoto}
                              alt="Property"
                            />
                          </div>

                          {/* Property Details Section */}
                          <div
                            className="propertyNewCard flatseeker-property-card"
                            id="propertyCard-body"
                          >
                            <div
                              className="text-decoration-none text-dark"
                              onClick={() => handleShow(property.id)}
                            >
                              <div id=" card-Heading">
                                {" "}
                                {/* <h2>{property.title} </h2>{" "} */}
                                <h2>{property.name} </h2>{" "}
                                <div
                                  id="card-location"
                                  className="row"
                                  style={{}}
                                >
                                  <div
                                    className="col d-flex align-items-center"
                                    id="col"
                                  >
                                    <span className="grey flatseekerprefrence">
                                      <FontAwesomeIcon
                                        className="grey"
                                        icon={faLocationDot}
                                      />
                                      <ul>
                                        {property.area
                                          .slice(0, 2)
                                          .map((area, index) => (
                                            <li key={index}>{area}</li>
                                          ))}
                                        {property.area.length > 2 && (
                                          <li>+{property.area.length - 2}</li>
                                        )}
                                      </ul>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div id="card-Details" className="row">
                                <div
                                  id="FlatseekerDetails"
                                  className="col flat-details"
                                >
                                  <span className="grey"> Rent </span>
                                  <p>₹ {property.budget}</p>
                                </div>

                                <div
                                  id="FlatseekerDetails"
                                  className="col flat-details"
                                >
                                  <span className="grey"> Looking For </span>{" "}
                                  <p>{property.gender}</p>
                                </div>

                                {property.flatType && (
                                  <div
                                    id="FlatseekerDetails"
                                    className="col flat-details"
                                  >
                                    <span className="grey"> Looking For </span>{" "}
                                    <p>{property.flatType}</p>
                                  </div>
                                )}
                              </div>

                              <div className="preferences-details">
                                <ul>
                                  {property.preference
                                    .slice(0, 3)
                                    .map((item, index) => {
                                      return <li key={index}>{item}</li>;
                                    })}
                                  {property.preference.length > 5 && (
                                    <li
                                      style={{
                                        backgroundColor: "black",
                                        color: "white",
                                      }}
                                    >
                                      + {property.preference.length - 3}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div
                              className="model-box"
                              style={{ width: "100%" }}
                            >
                              <Modal
                                show={selectedPropertyID === property.id}
                                onHide={handleClose}
                              >
                                {/* <Modal.Header closeButton>
                                <Modal.Title>Property Details</Modal.Title>
                              </Modal.Header> */}
                                <Modal.Body>
                                  <ParticularProperty
                                    propertyId={property.id}
                                  />
                                </Modal.Body>
                                {/* You can add a footer here if needed */}
                              </Modal>
                            </div>
                          </div>
                        </div>
                        <div className="card-down">
                          <div className="card-down-left"></div>
                          <div className="card-down-right">
                            <div className="call-icon">
                              <FontAwesomeIcon
                                className="white"
                                icon={faShare}
                                onClick={() => {
                                  handleShareClick(property);
                                }}
                              />
                            </div>
                            <div className="call-icon">
                              <FontAwesomeIcon
                                className="white"
                                icon={faPhone}
                                onClick={downloadlink}
                              />
                            </div>
                            <div className="msg-icon">
                              <FontAwesomeIcon
                                className="white"
                                icon={faComment}
                                onClick={downloadlink}
                              />
                            </div>
                          </div>
                        </div>

                        {property.isRentedout == "0" ? (
                          ""
                        ) : (
                          // <p className="rentout-flatseeker" >RentedIn</p>
                          <p
                            className="rentout-flatseeker"
                            style={{ backgroundColor: "red" }}
                          >
                            Rentedout
                          </p>
                        )}

                        {/* Owner and Contact Section */}
                      </div>
                    );
                  })}
                </div>
              )}
              {totalItems > 10 && (
                <div className="paginationBox">
                  <Button
                    className="paginationBtn"
                    onClick={goToPreviousPage}
                    disabled={currentPage === 0}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> Previous
                  </Button>
                  <Button
                    className="paginationBtn"
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div>
              {isLoading ? ( // Check if data is loading
                <div className="text-center fs-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : visibleData.length === 0 ? (
                <div className="text-center fs-4">No Result Found</div>
              ) : (
                <MapContainer addresses={addresses} height={"80vh"} />
              )}
            </div>
          )}

          <ExpirePopup isOpen={expirePopupmodal} onClose={ExpirePopupModal} />
          <Downloads
            isOpen={downloadmodel}
            onClose={() => {
              setdownloadmodel(false);
            }}
          />
          {/* Filter bar for mobile view */}
          <div className={`filter-modal ${filterBar ? "open" : ""}`}>
            <div className="modal-content">
              <Form>
                <div className="p-3 d-flex flex-column">
                  <div className="closeBox">
                    <span className="close" onClick={filterModal}>
                      <FontAwesomeIcon icon={faClose} />
                    </span>
                  </div>
                  <div className="col-md-12" id="filterBarMobileview">
                    <FormGroup className="mb-3">
                      <Label for="location">Location :</Label>
                      {/* <Input
                        id="location"
                        placeholder="search..."
                        value={city}
                        onChange={(e) => {
                          handleLocationChange(e.target.value);
                        }}
                      /> */}
                      <div className="location-groups mt-2">
                        {cityFilter &&
                          cityFilter.map((button) => (
                            <div
                              key={button.cityName}
                              className={`location-button rounded-pill ${
                                city === button.cityName ? "selected" : ""
                              }`}
                              value={city}
                              onClick={() => {
                                handleLocationChange(button.cityName);
                              }}
                            >
                              {button.cityName.charAt(0).toUpperCase() +
                                button.cityName.slice(1)}
                            </div>
                          ))}
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label for="location">Area :</Label>
                      <NearbyLocations
                        handleareaChange={handleareaChange}
                        area={area}
                      />
                      <div className="location-groups">
                        {area &&
                          area.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`location-button rounded-pill selected`}
                                onClick={() => {
                                  removearea(item);
                                }}
                              >
                                {item}
                              </div>
                            );
                          })}
                      </div>
                    </FormGroup>
                    {/* <FormGroup className="mt-3">
                      <Label>Occupancy Type :</Label>
                      <div className="location-groups">
                        {Occupancy.map((button) => (
                          <div
                            key={button.value}
                            className={`location-button rounded-pill ${
                              occupancyFilter[0] === button.value
                                ? "selected"
                                : ""
                            }`}
                            value={occupancyFilter}
                            onClick={() => {
                              handleOccupancyChange(button.value);
                            }}
                          >
                            {button.label}
                          </div>
                        ))}
                      </div>
                    </FormGroup> */}
                    <FormGroup className="mt-3">
                      <Label for="location">Type :</Label>

                      <div className="location-groups mt-2">
                        {selecttype &&
                          selecttype.map((button) => {
                            return (
                              <div
                                key={button.value}
                                className={`location-button rounded-pill ${
                                  type === button.value ? "selected" : ""
                                }`}
                                value={type}
                                onClick={() => {
                                  handletypechange(button.value);
                                }}
                              >
                                {button.value}
                              </div>
                            );
                          })}
                      </div>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <Label for="budget">Budget :</Label>
                      <div className="range-bar">
                        <input
                          type="number"
                          className="range-input"
                          min="5000"
                          max="50000"
                          value={budgetFilter}
                          step="1000"
                          onChange={(e) => setBudgetFilter(e.target.value)}
                          onKeyPress={handleBudgetChange}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <Label>Gender :</Label>
                      <div className="location-groups">
                        {gender.map((button) => (
                          <div
                            key={button.value}
                            className={`location-button rounded-pill ${
                              genderFilter === button.value ? "selected" : ""
                            }`}
                            value={genderFilter}
                            onClick={() => {
                              handleGenderChange(button.value);
                            }}
                          >
                            {button.label}
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="location">Shifting :</Label>
                      <Input
                        type="date"
                        id="location"
                        placeholder="search..."
                        className="my-mb"
                        // value={area}
                        onChange={(e) => {
                          handledateChange(e);
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <Label>Preferences :</Label>
                      <div className="location-groups">
                        {Preferences.map((button) => (
                          <div
                            key={button.value}
                            className={`location-button rounded-pill ${
                              preferencesFilter.includes(button.value)
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => {
                              handlePreferencesChange(button.value);
                            }}
                          >
                            {button.label}
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="mainContain col-md-8 d-sm-none">
          {!listView ? (
            <div className="mb-3" style={{ maxWidth: "100%" }}>
              {isLoading ? ( // Check if data is loading
                <div className="text-center fs-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : visibleData.length === 0 ? (
                <div className="text-center fs-4">No Result Found</div>
              ) : (
                <div
                  className="property-grid grid-gap flatseeker-card"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  {visibleData.map((property, index) => {
                    console.log(property);
                    return (
                      <div
                        className="property-card  row "
                        key={index}
                        id="propertyCard"
                        style={{
                          display: "flex",
                          padding: "15px",
                          marginBottom: "0px",
                        }}
                      >
                        {/* Property Image Section */}

                        <div className="card-upper">
                          <div
                            className="property-image property-new-image"
                            style={{ padding: "0px" }}
                          >
                            {/* <span className="gender">{property.gender}</span> */}
                            <img
                              className="propertyImage"
                              id="CarouselImage"
                              src={property.profilePhoto}
                              alt="Property"
                            />
                            {/* <div
                        className="icon-box flex  flat-seeker-items"
                        id="IconGroup"
                      >
                        <span
                          className={`bookmark-button ShareIcons heart ${
                            user &&
                            user.saved_property &&
                            user.saved_property.includes(property.id)
                              ? "bookmarked"
                              : ""
                          }`}
                          onClick={downloadlink}
                        >
                          {user &&
                          user.saved_property &&
                          user.saved_property.includes(property.id) ? (
                            <FontAwesomeIcon icon={fasolidHeart} />
                          ) : (
                            <FontAwesomeIcon icon={faHeart} />
                          )}
                        </span>
                        <span
                          className="ShareIcons"
                          style={{ zIndex: "1" }}
                          onClick={() => handleShareClick(property)}
                        >
                          <FontAwesomeIcon icon={faShareFromSquare} />
                        </span>
                        <span
                          className="ShareIcons this"
                          // onMouseEnter={() => handleMouseEnter(property.id)}
                          // onMouseLeave={handleMouseLeave}
                          onClick={downloadlink}

                        >
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </span>
                      </div> */}
                            {/* <div
                        className=" flatseeker-owner"
                        id="card-ButtonBox"
                        style={{ padding: "0px" }}
                      >
                        <div className="new-ownerBox">
                          <span></span> {property.name}
                        </div>
                      </div> */}
                          </div>

                          {/* Property Details Section */}
                          <div
                            className="propertyNewCard flatseeker-property-card"
                            id="propertyCard-body"
                          >
                            <Link
                              className="text-decoration-none text-dark"
                              to={`/particular-Flatseeker/${property.id}`}
                            >
                              <div id=" card-Heading">
                                {" "}
                                {/* <h2>{property.title} </h2>{" "} */}
                                <h2>{property.name} </h2>{" "}
                                {/* <div id="card-location" className="row" style={{}}>
                          <div
                            className="col d-flex align-items-center"
                            id="col"
                          >
                           
                            <span className="grey flatseekerprefrence">

                            <div className="areabox">
                            <FontAwesomeIcon
                              className="me-2 grey"
                              icon={faLocationDot}
                            />
                              Area:
                            </div>
                            
                              <ul>
                                {property.area.map((ar, index) => (
                                <li>{ar}</li>
                              ))}
                              </ul>
                              
                            </span>
                          </div>
                        </div> */}
                                <div
                                  id="card-location"
                                  className="row "
                                  style={{}}
                                >
                                  <div
                                    className="col d-flex align-items-center"
                                    id="col"
                                  >
                                    <span className="grey flatseekerprefrence">
                                      <FontAwesomeIcon
                                        className="grey"
                                        icon={faLocationDot}
                                      />

                                      <ul>
                                        <li>{property.area[0]}</li>
                                        &nbsp; &nbsp;{" "}
                                        {property.area[1] ? "..." : ""}
                                      </ul>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div id="card-Details" className="row">
                                {/* <div id="Details" className="col">
                            <span className="grey">Gender :</span>{" "}
                            {property.gender}
                          </div> */}
                                {/* <div id="Details" className="col">
                            <span className="grey">
                              Property Prefrence :
                            </span>{" "}
                            {property.type}
                          </div> */}

                                {/* {property.flatType && (
                            <div id="Details" className="col">
                              <span className="grey">Property Type :</span>{" "}
                              {property.flatType}
                            </div>
                          )} */}
                                {/* <div id="Details" className="col">
                            <span className="grey">Budget:</span>{" "}
                            {property.budget}/per month{" "}
                          </div> */}

                                {/* <div id="Details" className="col">
                            <span className="grey">No of persons </span>:{" "}
                            {property.noOfPerson} Rent
                          </div> */}
                                {/* {property.furnishedType && (
                            <div id="Details" className="col">
                              <span className="grey flatseekerprefrence">
                                Furnished Prefrence:
                                <ul>
                                {property.furnishedType.map(
                                  (item, index) => {
                                    return <li>{item}</li>;
                                  }
                                )}
                                </ul>
                               
                              </span>{" "}
                            </div>
                          )} */}
                                {/* <div id="Details" className="col">
                            <span className="grey flatseekerprefrence">
                              Preference :
                              <ul>
                              {property.preference.map((item, index) => {
                                return <li>{item}</li>;
                              })}
                              </ul>
                             
                            </span>
                          </div> */}

                                {/* {property.shiftingDate && (
                            <div id="Details" className="col">
                              <span className="grey">Shifting Date:</span>{" "}
                              {property.shiftingDate}{" "}
                            </div>
                          )} */}
                                {/* {property.occupancyType && (
                            <div id="Details" className="col">
                              <span className="grey flatseekerprefrence">
                                Occupancy type:
                                {property.occupancyType.map(
                                  (item, index) => {
                                    return <li>{item}</li>;
                                  }
                                )}
                              </span>{" "}
                            </div>
                          )} */}

                                <div
                                  id="FlatseekerDetails"
                                  className="col flat-details"
                                >
                                  <span className="grey"> Rent </span>
                                  <p>₹ {property.budget}</p>
                                </div>

                                <div
                                  id="FlatseekerDetails"
                                  className="col flat-details"
                                >
                                  <span className="grey"> Looking For </span>{" "}
                                  <p>{property.gender}</p>
                                </div>

                                {property.flatType && (
                                  <div
                                    id="FlatseekerDetails"
                                    className="col flat-details"
                                  >
                                    <span className="grey"> Looking For </span>{" "}
                                    <p>{property.flatType}</p>
                                  </div>
                                )}
                              </div>

                              <div className="preferences-details">
                                <ul>
                                  {property.preference
                                    .slice(0, 3)
                                    .map((item, index) => {
                                      return <li key={index}>{item}</li>;
                                    })}
                                  {property.preference.length > 5 && (
                                    <li
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "black",
                                      }}
                                    >
                                      {property.preference.length - 3} more...
                                    </li>
                                  )}
                                </ul>
                              </div>

                              {/* <div
                            className="owner-buttons"
                            style={{ paddingTop: "10px" }}
                          > */}
                              {/* <Button
                            id="contact-owner"
                            className="btn btn-secondary"
                            onClick={downloadlink}
                          >
                            {user &&
                            user.contacted_property &&
                            user.contacted_property.includes(
                              property.id
                            ) ? (
                              <a
                                href={`tel:${property.phone}`}
                                style={{ textDecoration: "none" }}
                              >
                                <FontAwesomeIcon icon={faPhone} />{" "}
                                {property.number}
                              </a>
                            ) : (
                              "Contact Owner"
                            )}
                          </Button>
                          <Button
                            id="send-message"
                            class=" mobileAndTab-hide btn btn-secondary "
                            onClick={downloadlink}
                          >
                            Send Message
                          </Button> */}
                              {/* <Link to={"https://wa.me/9316066832"} className="needHelp" style={{display:"inline-block", textAlign: "center", width: "100%"}}>
                      Need Help?
                    </Link> */}
                              {/* </div> */}
                            </Link>
                          </div>
                        </div>
                        <div className="card-down">
                          <div className="card-down-left"></div>
                          <div className="card-down-right">
                            <div className="call-icon">
                              <FontAwesomeIcon
                                className="white"
                                icon={faShare}
                                onClick={() => {
                                  handleShareClick(property);
                                }}
                              />
                            </div>
                            <div className="call-icon">
                              <FontAwesomeIcon
                                className="white"
                                icon={faPhone}
                                onClick={downloadlink}
                              />
                            </div>
                            <div className="msg-icon">
                              <FontAwesomeIcon
                                className="white"
                                icon={faComment}
                                onClick={downloadlink}
                              />
                            </div>
                          </div>
                        </div>

                        {property.isRentedout == "0" ? (
                          ""
                        ) : (
                          // <p className="rentout-flatseeker" >RentedIn</p>
                          <p
                            className="rentout-flatseeker"
                            style={{ backgroundColor: "red" }}
                          >
                            Rentedout
                          </p>
                        )}

                        {/* Owner and Contact Section */}
                      </div>
                    );
                  })}
                </div>
              )}
              {totalItems > 10 && (
                <div className="paginationBox">
                  <Button
                    className="paginationBtn"
                    onClick={goToPreviousPage}
                    disabled={currentPage === 0}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> Previous
                  </Button>
                  <Button
                    className="paginationBtn"
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                  {console.log(totalPages)}
                </div>
              )}
            </div>
          ) : (
            <div>
              {isLoading ? ( // Check if data is loading
                <div className="text-center fs-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : visibleData.length === 0 ? (
                <div className="text-center fs-4">No Result Found</div>
              ) : (
                <MapContainer addresses={addresses} height={"80vh"} />
              )}
            </div>
          )}
          {/* <Signup isOpen={signinmodal} onClose={signinModal} /> */}
          {/* <LoginModal isOpen={loginmodal} onClose={loginModal} /> */}
          {/* <CustomPopup
        isOpen={customPopupmodal}
        data={customePopupData}
        onClose={customPopupModal}
      /> */}
          <ExpirePopup isOpen={expirePopupmodal} onClose={ExpirePopupModal} />
          <Downloads
            isOpen={downloadmodel}
            onClose={() => {
              setdownloadmodel(false);
            }}
          />
          {/* Filter bar for mobile view */}
          <div
            className={`filter-modal mobile-filter-modal ${
              filterBar ? "open" : ""
            }`}
          >
            <div className="modal-content">
              <Form>
                <div className="p-3 d-flex flex-column">
                  <div className="closeBox">
                    <span className="close" onClick={filterModal}>
                      <FontAwesomeIcon icon={faClose} />
                    </span>
                  </div>
                  <div className="col-md-12" id="filterBarMobileview">
                    <FormGroup>
                      <Label for="location">Location :</Label>
                      {/* <Input
                        id="location"
                        placeholder="search..."
                        value={city}
                        onChange={(e) => {
                          handleLocationChange(e.target.value);
                        }}
                      /> */}
                      <div className="location-groups mt-2">
                        {cityFilter &&
                          cityFilter.map((button) => (
                            <div
                              key={button.cityName}
                              className={`location-button rounded-pill ${
                                city === button.cityName ? "selected" : ""
                              }`}
                              value={city}
                              onClick={() => {
                                handleLocationChange(button.cityName);
                              }}
                            >
                              {button.cityName.charAt(0).toUpperCase() +
                                button.cityName.slice(1)}
                            </div>
                          ))}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="location">Area :</Label>
                      <NearbyLocations
                        handleareaChange={handleareaChange}
                        area={area}
                      />
                      <div className="location-groups">
                        {area &&
                          area.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={`location-button rounded-pill selected`}
                                onClick={() => {
                                  removearea(item);
                                }}
                              >
                                {item}
                              </div>
                            );
                          })}
                      </div>
                    </FormGroup>
                    {/* <FormGroup className="mt-3">
                      <Label>Occupancy Type :</Label>
                      <div className="location-groups">
                        {Occupancy.map((button) => (
                          <div
                            key={button.value}
                            className={`location-button rounded-pill ${
                              occupancyFilter[0] === button.value
                                ? "selected"
                                : ""
                            }`}
                            value={occupancyFilter}
                            onClick={() => {
                              handleOccupancyChange(button.value);
                            }}
                          >
                            {button.label}
                          </div>
                        ))}
                      </div>
                    </FormGroup> */}
                    <FormGroup className="mt-3">
                      <Label for="location">Type :</Label>

                      <div className="location-groups mt-2">
                        {selecttype &&
                          selecttype.map((button) => {
                            return (
                              <div
                                key={button.value}
                                className={`location-button rounded-pill ${
                                  type === button.value ? "selected" : ""
                                }`}
                                value={type}
                                onClick={() => {
                                  handletypechange(button.value);
                                }}
                              >
                                {button.value}
                              </div>
                            );
                          })}
                      </div>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <Label for="budget">Budget :</Label>
                      <div className="range-bar">
                        <input
                          type="number"
                          className="range-input"
                          min="5000"
                          max="50000"
                          value={budgetFilter}
                          step="1000"
                          onChange={(e) => setBudgetFilter(e.target.value)}
                          onKeyPress={handleBudgetChange}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <Label>Gender :</Label>
                      <div className="location-groups">
                        {gender.map((button) => (
                          <div
                            key={button.value}
                            className={`location-button rounded-pill ${
                              genderFilter === button.value ? "selected" : ""
                            }`}
                            value={genderFilter}
                            onClick={() => {
                              handleGenderChange(button.value);
                            }}
                          >
                            {button.label}
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="location">Shifting :</Label>
                      <Input
                        type="date"
                        id="location"
                        placeholder="search..."
                        // value={area}
                        onChange={(e) => {
                          handledateChange(e);
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <Label>Preferences :</Label>
                      <div className="location-groups">
                        {Preferences.map((button) => (
                          <div
                            key={button.value}
                            className={`location-button rounded-pill ${
                              preferencesFilter === button.value
                                ? "selected"
                                : ""
                            }`}
                            value={preferencesFilter}
                            onClick={() => {
                              handlePreferencesChange(button.value);
                            }}
                          >
                            {button.label}
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <div className="location-groups">
                        <input
                          type="Button"
                          className="form-control btn btn-primary"
                          value={"Reset Filter"}
                          onClick={resetfilter}
                        />
                        {/* <input
                          type="Button"
                          className="form-control btn btn-primary mt-2"
                          value={"Apply Filter"}
                          onClick={() => {
                            applyFilters();
                            setFilterBar(false); // Close the modal when the button is clicked
                          }}
                        /> */}
                      </div>
                    </FormGroup>

                    {/* <FormGroup className="mt-3">
                  <Label>Ocupancy Type :</Label>
                  <div className="location-groups">
                    {Ocupancy.map((button) => (
                      <div
                        key={button.value}
                        className={`location-button rounded-pill ${
                          occupancyFilter === button.value ? "selected" : ""
                        }`}
                        value={locationFilter}
                        onClick={() => {
                          handleOccupancyChange(button.value);
                        }}
                      >
                        {button.label}
                      </div>
                    ))}
                  </div>
                </FormGroup> */}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlatSeekers;
