import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./navbar.css";
import LoginModal from "./Login.js";
import Signup from "./Signup";
import {
  faAngleRight,
  faAnglesDown,
  faBars,
  faCrown,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import "./demo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Button } from "reactstrap";
import axios from "axios";

const Navbar = ({ user }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const [loginmodal, setLoginmodal] = useState(false);
  const [signinmodal, setSigninmodal] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const [showSubMenu, setShowSubMenu] = useState(false);
  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };
  const loginModal = () => {
    setSigninmodal(false);
    setLoginmodal(!loginmodal);
    handleShowNavbar();
  };
  const signinModal = () => {
    setLoginmodal(false);
    setSigninmodal(!signinmodal);
    handleShowNavbar();
  };

  const CITY_STORAGE_KEY = "active-city-data";
  const storedData = localStorage.getItem(CITY_STORAGE_KEY);
  const [showCityModal, setShowCityModal] = useState(false);
  const [city, setcity] = useState(localStorage.getItem("city"));
  const [storecity, setstorecity] = useState(
    localStorage.getItem("active-city-data")
  );

  useEffect(() => {
    const getcity = async () => {
      // Check for stored data first

      if (!storecity) {
        const apiResponse = await axios.get(
          `${process.env.REACT_APP_API_IP}/v2/cjebchu/website/ckijevcu/active-city`
        );
        localStorage.setItem(
          CITY_STORAGE_KEY,
          JSON.stringify(apiResponse.data)
        );
      }
      if (city == null) {
        setShowCityModal(true);
      }

      // Fallback to API call if not found or invalid
    };
    getcity();
  });

  let allcity = localStorage.getItem("active-city-data");

  return (
    <nav className="navbar shadow-sm sticky-top ">
            `{" "}

      {city == null && (
        <div
          className={`city-selection-modal ${
            window.innerWidth <= 768
          } ? 'mobile-view' : ''}`}
        >
          <div className="city-selection-content">
            <div className="city-selection-header">
              <h2>Select a City</h2>
              <button
                onClick={() => {
                  setShowCityModal(false);
                }}
                className="close-modal"
              >
              </button>
            </div>
            <div className="city-grid">
              {allcity &&
                JSON.parse(allcity).map((Button, INDEX) => {
                  return (
                    <div
                      key={INDEX}
                      className={`city-item ${
                        localStorage.getItem("city") === Button.cityName
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => {
                        localStorage.setItem("city", Button.cityName);
                        window.location.reload();
                      }}
                    >
                      <img
                        src={Button.webImageUrl}
                        alt={Button.cityName}
                        className="city-image"
                      />
                      <p>{Button.cityName}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      <div className="whatsapp-button">
        <Link
          target="_blank"
          rel="noreferrer"
          to="https://api.whatsapp.com/send?phone=+919316066832&text=Hello%20there,%20I%20have%20a%20question%20for%20you."
          className=""
        >
          <div className="icon-flex">
            <p>Help</p>
            <FontAwesomeIcon className="whats-icon" icon={faWhatsapp} />
          </div>
        </Link>
      </div>
      <div className="Navcontainer">
        <div className="logo">
          <Link id="logoBox" className="navbar-brand" to="/">
            <img
              src="/emenities/logo.png"
              width={"500px"}
              alt="logo"
              className="mobileAndTab-hide"
            />
            <span className="navbar-text">citynect</span>
          </Link>
        </div>
        <div className="menu-icon rounded" onClick={handleShowNavbar}>
          {showNavbar ? (
            <FontAwesomeIcon icon={faX} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          {/* For Mobile View Only */}
          <ul>
            <div>
              <li id="Myaccount">
                <Link to="https://apps.apple.com/in/app/citynect/id6503302420?platform=iphone">
                  <span>
                    <img src="/emenities/Appstore.png" />
                  </span>
                  Appstore
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=com.codingislife.citynect">
                  {" "}
                  <span>
                    <img src="/emenities/game.png" />
                  </span>
                  Playstore
                </Link>
              </li>
            </div>
            <li onClick={handleShowNavbar}>
              <NavLink to="/paying-guest">PG</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <NavLink to="/sharingflats">Sharing Flats</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <NavLink to="/privateflats">Private Flats</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <NavLink to="/flatseekers">Flat Seekers</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
            <NavLink to="https://blogs.citynect.in/">Blog</NavLink>
            </li>
            {/* <li className="listProperty" onClick={handleShowNavbar}>
              <Link to="/ListProperty">
                List Property{" "}
                <span
                  className="badge rounded-pill text-bg-warning"
                  style={{ fontWeight: "lighter", paddingBottom: "2px" }}
                >
                  Free
                </span>
              </Link>
            </li> */}
          </ul>
        </div>
          <div className="rightBox">
            <Link to="https://apps.apple.com/in/app/citynect/id6503302420?platform=iphone">
              <span>
                <img src="/emenities/Appstore.png" />
              </span>
              Appstore
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.codingislife.citynect">
              {" "}
              <span>
                <img src="/emenities/game.png" />
              </span>
              Playstore
            </Link>
          </div>
      </div>

      {/* Signin Modal */}
      {/* <Signup isOpen={signinmodal} onClose={signinModal} /> */}
      {/* Login Modal */}
      {/* <LoginModal isOpen={loginmodal} onClose={loginModal} /> */}
    </nav>
  );
};

export default Navbar;
