import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions my-5">
      <div className="page">
        <div className="content">
          <h1>Terms & condition</h1>
          <p>
            These terms and conditions of use (the “terms” or “Agreement”)
            govern our social media-based websites, accounts, pages and
            applications (or collectively, our “sites”) that belong to, or are
            managed by VOCAL CONNECT PVT LTD (citynect) (“VOCAL CONNECT PVT
            LTD”,”citynect”, “we”, “our” and/or “us”). By accessing and/or using
            our app/sites, you are agreeing to be bound by these terms, all
            applicable laws and regulations, and any other applicable policies,
            terms and guidelines established by vocal connect pvt ltd and those
            of any third parties that host our sites, Indian Law as amended from
            time to time. If you do not agree with all of these terms or cannot
            form a legally binding contract, please do not continue to use our
            platforms.
          </p>
          <p>
            While citynect (vocal connect pvt ltd) has official profiles on a
            number of social networks, this does not mean that we are endorsing
            their products or services. From time to time we may share content
            on our sites that we think is interesting. This does not mean we are
            agreeing or supporting a point of view or endorsing a product or
            service. We may occasionally mention a user, tweet, re-tweet or
            repost links to third-party sites. However, this does not constitute
            an endorsement of the individual, site, product or company.
          </p>
          <p>
            We cannot and do not guarantee the accuracy of content posted on our
            app/website by us or third parties. We do not, in any way, adopt,
            endorse, or guarantee the accuracy of content posted by others. We
            are not legally responsible for content posted by third parties on
            our sites. We do not endorse clicking on links posted by other
            social media users. We do not provide investment, legal, tax or
            other specialist advice through our app/website. We may disclose
            your communications and activities on our app/site in response to
            requests by any government agency.
          </p>
          <p>
            Those that have a business relationship with citynect (vocal connect
            pvt ltd) and/or any other interests are responsible for adhering to
            any previous restrictions and/or obligations regarding information
            that may be deemed non-public. These terms in no way replace terms
            outlined in any other agreement executed between citynect (vocal
            connect pvt ltd) and you.
          </p>
          <p>
            <strong>Use Limitations and Guidelines</strong>
            <br />
            You agree to comply with the social media guidelines outlined herein
            with regard to any content that you post/share on our sites, and you
            agree to our right to moderate and delete any content at our sole
            discretion. You also agree that your activities on our sites will
            not violate any applicable law, regulation or terms of any existing
            agreements you may have with citynect (vocal connect pvt ltd). Any
            unauthorized use of our app/site or misuse of any information posted
            to a site is strictly prohibited. Your use of our app/sites is
            subject to the following guidelines: .
          </p>
          <p>
            <ul>
              <li>
                You agree that you are responsible for any content that you
                submit to our mobile app/websites, including comments,
                photographs and other media.
              </li>
              <li>
                Posting Content to Our sites:
                <ul>
                  <li>
                    Stay relevant – stay on topic with relevant information that
                    others may find useful.
                  </li>
                  <li>Be respectful – maintain a friendly environment.</li>
                  <li>
                    Do not post material that is inappropriate or that could
                    reasonably be deemed defamatory, offensive or obscene.
                  </li>
                  <li>
                    No investment advice – do not post recommendations and/or
                    advice about specific securities, products, or services.
                  </li>
                  <li>
                    Do not use these online spaces for illegal purposes or to
                    breach any laws.
                  </li>
                  <li>
                    Do not harass, cause distress or inconvenience to, or
                    infringe the rights of, another person. 
                  </li>
                  <li>
                    Do not submit anything to our sites that will or may violate
                    the rights of a third party, including but not limited to
                    copyright, trademark, and/or privacy rights.  
                  </li>
                  <li>
                    Do not use our sites or content on them in any way not
                    permitted by these terms.
                  </li>
                </ul>
              </li>
              <li>
                You agree that you shall not place false or misleading
                information on our app/sites or upload any computer
                programs—including but not limited to viruses, Trojan horses,
                worms, and/or time bombs—that may interfere with the proper
                operation of our mobileapp/websites.
              </li>
              <li>
                We reserve the right to remove comments and/or posts. We also
                reserve the right to restrict users who don’t follow these
                guidelines, have online pages containing offensive material
                and/content, or for any other reason.
              </li>
              <li>
                By posting any content on any site, you grant to citynect (vocal
                connect pvt ltd) the irrevocable right to reproduce, distribute,
                publish, display, edit, modify, create derivative works from and
                otherwise use such content for any purpose, in any form, and on
                any media.
              </li>
            </ul>
          </p>
          <p>
            <strong>Privacy </strong>
            <br />
            Please note that when visiting any citynect (vocal connect pvt ltd)
            app/site, you are also subject to the terms and conditions of our
            privacy notices, as well as the terms of service and privacy
            policies of third-party hosts, when applicable. Do not send or post
            personal information via our social media mobiel apps/websites.
            Never disclose any financial or personal information on any citynect
            (vocal connect pvt ltd) mobileapp/website. We will never ask you for
            your Social Security number, account information, passwords, PINs or
            other personally identifiable information via social media or email.
            You acknowledge that you assume all responsibility related to the
            security, privacy and risks inherent in sending any content over the
            Internet. We are not responsible for the privacy or security
            practices of the social media mobile app/sites we utilize. Please
            review their policies.
          </p>
          <p>
            <strong>Monitoring </strong>
            <br />
            We have no obligation to monitor the use of our mobileapp/website by
            users. You acknowledge and agree that we reserves the right to, and
            may from time to time, monitor any and all information transmitted
            or received through our sites for operational and other purposes.
            During monitoring, information may be examined, recorded, copied,
            and used for authorized purposes. Use of our mobileapp/website
            constitutes consent to such monitoring. Furthermore, We reserves the
            right at all times to disclose any information posted on any portion
            of our app/website as necessary to satisfy any law, regulation or
            governmental request, or to refuse to post, or to remove, any
            information or materials, in whole or in part, that in citynect
            (vocal connect pvt ltd) sole and absolute discretion are
            objectionable or in violation of this Agreement.
          </p>
          <p>
            <strong>Copyright/Use License </strong>
            <br />
            The content provided in our app/website is owned or licensed by
            citynect (vocal connect pvt ltd) unless otherwise indicated. Unless
            expressly noted otherwise, everything on our app/ sites is protected
            by intellectual property rights held by vocal connect pvt ltd or
            other parties that have allowed citynect (vocal connect pvt ltd) to
            use their materials. All rights not expressly granted are reserved.
            Permission is granted to temporarily download one copy of citynect
            (vocal connect pvt ltd)-provided content on citynect (vocal connect
            pvt ltd) for personal, non-commercial use only. This is the grant of
            a license, not a transfer of title, and under this license you may
            not:
          </p>
          <p>
            <li>Modify or copy the content except as otherwise provided;</li>
            <li>
              Use the content for any commercial purpose, or for any public
              display (commercial or non-commercial);
            </li>
            <li>
              Remove any copyright or other proprietary notices from the
              content; or
            </li>
            <li>
              Transfer the content to another person or “mirror” the content on
              any other server.
            </li>
            This license shall automatically terminate if you violate any of
            these restrictions and may be terminated by citynect (vocal connect
            pvt ltd) at any time. Upon completing your review of the content or
            upon the termination of this license, you must destroy any
            downloaded materials in your possession, whether in electronic or
            printed format.
          </p>
          <p>
            <strong>Disclaimers </strong>
            <br />
            The materials on citynect (vocal connect pvt ltd) app/ sites are
            provided “as is.” we makes no warranties, expressed or implied, and
            hereby disclaims and negates all warranties, including without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, and non-infringement of
            intellectual property or other violation of rights. Further, we do
            not warrant or make any representations concerning the accuracy,
            likely results, or reliability of the use of the materials on its
            sites or otherwise relating to such materials or on any sites linked
            to these sites. The information and content provided on our sites,
            including without limitation text, graphics and images, are for
            informational purposes only, not as an offer to sell or the
            solicitation for the purchase of stock of citynect (vocal connect
            pvt ltd), or any other issuer or company. citynect (vocal connect
            pvt ltd) is not responsible for any content posted by users,
            including posts made by employees or agents who are not authorized
            administrators of our app/ sites. Content posted by others is not
            edited by citynect (vocal connect pvt ltd) and does not necessarily
            represent the views or positions of citynect (vocal connect pvt
            ltd). We are not liable for the acts or omissions of third party
            service providers or hosts of the sites.
          </p>
          <p>
            <strong>Limitations</strong>
            <br /> The use of our app/sites is entirely at your own risk, and in
            no event shall citynect (vocal connect pvt ltd), be liable for any
            direct, indirect, incidental, consequential, special, exemplary,
            punitive or any other monetary or other damages, fees, fines,
            penalties, or liabilities (including, without limitation, damages
            for or profit, or due to business interruption) arising out of or
            relating to our app/sites, your use or inability to use the content
            on our app/sites, even if citynect (vocal connect pvt ltd) has been
            notified orally or in writing of the possibility of such damage.
            citynect (vocal connect pvt ltd) will not be responsible in any way
            for loss or damage that results from your failure to comply with
            these terms or other applicable guidelines, or from any technical,
            human or software errors or failures found within our app/sites. We
            are not liable for the acts or omissions of any such third party
            service providers. Users must comply with any third party provider’s
            terms.
          </p>
          <p>
            <strong>Third-Party Links </strong>
            <br />
            We have not reviewed all of the third-party sites linked to its
            sites and are not responsible for the contents of any such linked
            site. The inclusion of any third-party link does not imply
            endorsement by us. Use of any such linked web site is at the user’s
            own risk. You understand that when going to a third-party web site,
            that site is governed by the third party’s privacy policy and terms
            of use, and the third party is solely responsible for the content
            and offerings presented on its website.
          </p>
          <p>
            <strong>Site Modification or Termination</strong>
            <br /> citynect (vocal connect pvt ltd) may modify the features,
            content and functionality of its app/sites, or terminate entirely
            any of its app/sites, at any time without prior notice or liability
            to you. Modification or termination of these terms will not affect,
            or result in the termination or modification, of any other existing
            agreements you may have with Wilshire Phoenix.
          </p>
          <p>
            <strong>Social Media Terms of Use Modifications</strong>
            <br />
            We may revise these terms of use for its sites at any time without
            notice. These terms of use may be updated at any time and/or each
            time a social networking site updates their terms of use. By using
            these app/site you are agreeing to be bound by the then current
            version of these terms of use. Any modifications to these terms
            shall be effective as of the date posted. We encourage you to
            periodically review these terms.
          </p>
          <p>
            <strong>Relationship </strong>
            <br />
            Your accessing of our app/ sites in no way shall be deemed to create
            an agency or employee-employer relationship of any kind between you
            and citynect (vocal connect pvt ltd), nor do these terms extend
            rights to any third party. You also agree that in the course of your
            accessing and using our app/site, that no relationship is formed
            between you and Wilshire Phoenix that is either confidential or
            fiduciary in nature beyond any existing relationship you may have
            with us.
          </p>
          <p>
            <strong>Termination of Use </strong>
            <br />
            We may, in its sole discretion, terminate your use of our sites at
            any time. citynect (vocal connect pvt ltd) reserves the right to
            change, suspend or discontinue all or any aspects of our app/sites
            at any time without prior notice.
          </p>
          <p>
            <strong>Jurisdiction and Miscellaneous</strong> <br />
            This Agreement shall be construed and enforced in accordance with
            the laws of the India without regard to the principles of conflicts
            of law. This Agreement is a personal agreement between you and
            citynect (vocal connect pvt ltd), and no one shall be a third party
            beneficiary to this Agreement. Although you acknowledge that We will
            have the ability to enforce its rights in any court of competent
            jurisdiction, you hereby acknowledge and agree that the courts of
            the State of the county shall have exclusive jurisdiction over any
            dispute relating to these terms and your use of our app/sites, that
            you shall submit to that jurisdiction and those courts, and that you
            expressly waive any and all rights you may have, or that may
            hereafter arise, to contest the propriety of such choice of
            jurisdiction and venue, including issues of forum non conveniens.
            You acknowledge and agree that you may not bring a claim against
            citynect (vocal connect pvt ltd) relating to your use of our sites
            after more than one year has passed since the event occurred that
            gave rise to the claim, while there is no such limitation on
            citynect (vocal connect pvt ltd)’s right to bring claims against
            you. You acknowledge and agree that the disclaimers, including the
            warranty disclaimers, and the liability and remedy limitations in
            this Agreement are material terms of this Agreement and that they
            have been taken into account in the decision by citynect (vocal
            connect pvt ltd) to provide our sites hereunder. You waive your
            right to trial by jury of any or all issues arising in any action or
            proceeding between you, or your successors, and Wilshire Phoenix or
            its successors, under or connected with this Agreement or any of its
            provisions. If any provision of this Agreement shall be unlawful,
            void, or for any reason unenforceable, then that provision shall be
            deemed severable from this Agreement, shall be enforced to the
            fullest extent allowed by law as to effect the intention of the
            parties, and shall not affect the validity and enforceability of any
            remaining provisions. This Agreement and any posted operating rules
            constitute the entire agreement of the parties with respect to the
            subject matter hereof, and supersede all prior or contemporaneous
            communications and proposals, whether oral or written, between the
            parties with respect to such subject matter. No waiver of any
            provision or any right granted hereunder will be effective unless
            set forth in a written instrument signed by the waiving party. No
            waiver by either party of any breach or default hereunder shall be
            deemed a waiver of any subsequent breach or default. The titles and
            subtitles used in this Agreement are used for convenience only and
            are not to be considered in construing or interpreting this
            Agreement.
          </p>
          <p>
            For any queries or help mail us<br />
            <strong>help@citynect.in</strong>
            <br />
            <strong>mail.cityride.info@gmail.com</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
