import React from 'react';
import "./CitySelectionModal.css";

const CitySelectionModal = ({ cities, onClose, onSelectCity, selectedCity, isMobileView, handleLocationChange, cityFilter = [] }) => {
  
  return (
    <div className={`city-selection-modal ${isMobileView ? 'mobile-view' : ''}`}>
      <div className="city-selection-content">
        <div className="city-selection-header">
          <h2>Select a City</h2>
          <button onClick={onClose} className="close-modal">✕</button>
        </div>
        <div className="city-grid">
          {/* Ensure cityFilter is an array before using .map() */}
          {Array.isArray(cityFilter) && cityFilter.length > 0 ? (
            cityFilter.map((Button, INDEX) => (
              <div
                key={INDEX}
                className={`city-item ${selectedCity === Button.cityName ? 'selected' : ''}`}
                onClick={() => { 
                  handleLocationChange(Button.cityName); 
                  onSelectCity(Button.cityName);
                }}
              >
                <img src={Button.webImageUrl} alt={Button.cityName} className="city-image" />
                <p>{Button.cityName}</p>
              </div>
            ))
          ) : (
            <p>No cities available</p>  // Display message if no cities are available
          )}
        </div>
      </div>
    </div>
  );
};

export default CitySelectionModal;
