// import React, { useState, useEffect } from "react";
// import usePlan from "../contexts/planContext";
// import { Loading } from "./shared/Loading";

// const CCavenueTest = () => {
//   const [encryptedData, setEncryptedData] = useState("");
//   const accessCode = "AVDU05KK33AU22UDUA";
//   // const accessCode = "AVSU35KK98CI90USIC";
//   const [isLoading, setIsLoading] = useState(false);
//   const { plan } = usePlan();

//   const fetchEncryptedData = async () => {
//     try {
//       // setIsLoading(true); // Assuming you have a state `isLoading` to track loading status
//       const response = await fetch(
//         `${process.env.REACT_APP_API_IP}/api/v2/ccavenue/encryptParameters`,
//         {
//           method: "POST",
//           body: JSON.stringify({ "customerId": localStorage.getItem('user'), "amount": plan.price }),
//           headers: { "Content-Type": "application/json" },
//         }
//       );
//       if (response.ok) {
//         setIsLoading(false);
//         const resp = await response.json()
//         localStorage.setItem('orderId', resp.orderId)
//         setEncryptedData(resp.encryptedData)
//       } else {
//         setIsLoading(false);
//         console.log("Error fetching encrypted data");
//       }
//     } catch (error) {
//       setIsLoading(false);
//       console.log("Error: ", error);
//     }
//   }

//   useEffect(() => {
//     setIsLoading(true);
//     fetchEncryptedData();
//   }, []);

//   return (
//     <div className="text-center mt-4">
//       {isLoading ? (
//         <div className="loader"></div>
//       ) : (
//         <iframe className="iframe-set"
//           title="payment gateway"
//           // src={`https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&access_code=${accessCode}&encRequest=${encryptedData}`}
//           src={`https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&access_code=${accessCode}&encRequest=${encryptedData}`}
//         ></iframe>
//       )}
//     </div>
//   );
// };

// export default CCavenueTest;

import React, { useState, useEffect } from "react";
import usePlan from "../contexts/planContext";
import { Loading } from "./shared/Loading";

const CCavenueTest = () => {
  const [encryptedData, setEncryptedData] = useState("");
  const accessCode = "AVSU35KK98CI90USIC";
  const [isLoading, setIsLoading] = useState(false);
  const { plan } = usePlan();

  const fetchEncryptedData = async () => {
    try {
      // setIsLoading(true); // Assuming you have a state `isLoading` to track loading status
      const response = await fetch(
        `${process.env.REACT_APP_API_IP}/api/v2/ccavenue/encryptParameters`,
        {
          method: "POST",
          body: JSON.stringify({
            customerId: localStorage.getItem("user"),
            amount: plan.price,
          }),
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.ok) {
        const resp = await response.json();
        localStorage.setItem("orderId", resp.orderId);
        setEncryptedData(resp.encryptedData);
      } else {
        setIsLoading(false);
        console.log("Error fetching encrypted data");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchEncryptedData();
  }, []);

  return (
    <div className="text-center mt-4">
      {isLoading && <div className="loader"></div>}
      <iframe
        className="iframe-set"
        title="payment gateway"
        onLoad={() => {
          setIsLoading(false);
        }}
        src={`https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&access_code=${accessCode}&encRequest=${encryptedData}`}
        // src={`https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&access_code=${accessCode}&encRequest=${encryptedData}`}
      ></iframe>
    </div>
  );
};

export default CCavenueTest;
