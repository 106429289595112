import {
  faHeart as fasolidHeart,
  faEllipsisVertical,
  faLocationDot,
  faPhone,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart,
  faShareFromSquare,
} from "@fortawesome/free-regular-svg-icons";
import "./perticularflat.css";

import ExpirePopup from "./Others/ExpirePopup.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "./Others/TabSelector.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import "./ParticularProperty.css";
import { Button, Image } from "react-bootstrap";
import { useEffect } from "react";

import CustomCarousel from "./Others/CustomCarousel.js";
import Downloads from "./Others/Downloadmodal.jsx";
const ParticularProperty = ({ user, propertyId }) => {
  const { id } = useParams();

  const [signinmodal, setSigninmodal] = useState(false);

  const [expirePopupmodal, setExpirePopupModal] = useState(false);

  const ExpirePopupModal = () => {
    if (expirePopupmodal) {
      window.location.href = "/premium";
    }
    setExpirePopupModal(!expirePopupmodal);
  };
  const userAgent = navigator.userAgent;
  // alert(userAgent);
  const osType = (() => {
    if (userAgent.includes("Windows")) {
      return "Windows";
    } else if (userAgent.includes("Mac")) {
      return "Mac OS";
    } else if (userAgent.includes("Android")) {
      return "Android";
    } else {
      return "Unknown";
    }
  })();
  const [downloadmodel, setdownloadmodel] = useState(false);

  const downloadlink = () => {
    // if (osType ==-
    if (osType == "Android") {
      window.open(
        "https://play.google.com/store/apps/details?id=com.codingislife.citynect",
        "_blank",
        "rel=noopener noreferrer"
      );
    } else if (osType == "Mac OS") {
      window.open(
        "https://apps.apple.com/",
        "_blank",
        "rel=noopener noreferrer"
      );
    } else {
      setdownloadmodel(true);
    }
  };

  const signinModal = () => setSigninmodal(!signinmodal);
  const [property, setProperty] = useState();
  const [selectedTab, setSelectedTab] = useTabs(["details", "pricing"]);
  const [showReportBlock, setShowReportBlock] = useState(false);
  const toggleReportBlock = () => {
    setShowReportBlock(!showReportBlock);
  };
  const [showReportButtonForProperty, setShowReportButtonForProperty] =
    useState(null);
  const handleMouseEnter = (propertyId) => {
    setShowReportButtonForProperty(propertyId);
  };
  const handleShareClick = async () => {
    console.log(property);
    try {
      if (navigator.share) {
        await navigator.share({
          text: `
                  Hey, ${property.name} is looking for a ${property.type}🏡
                  Gender: ${property.gender}
                  📍 Preferred Locations: ${[property.area].map(
                    (item, index) => item
                  )}
                   ₹ Budget: ${property.budget}
                  -> Check out more details on the Citynect app:
                  https://play.google.com/store/apps/details?id=com.codingislife.citynect
                  citynect - Simplifying Bachelor Housing Search [Flat & Flatmates India]`,
        });
      } else {
        // Fallback for browsers that don't support the Web Share API
        console.log("Web Share API is not supported.");
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };
  const handleMouseLeave = () => {
    setShowReportButtonForProperty(null);
  };
  const reportProperty = async (reason, id, ownerId) => {
    if (user) {
      try {
        const data = {
          userId: user.id,
          propertyId: id,
          ownerId: ownerId,
          reportTopic: reason,
        };
        const response = await fetch(
          `${process.env.REACT_APP_API_IP}/report/Report-Property`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        if (response.ok) {
          toast.success("Property Reported Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          console.error("Failed to submit property:", response.status);
        }
      } catch (error) {
        console.error("Error submitting property:", error);
      }
    } else {
      toast.error("Login to report Property", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const toggleBookmark = async (id) => {
    if (!user) {
      signinModal();
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_IP}/user/saved`,
          {
            method: "POST",
            body: JSON.stringify({ id: user.id, propertyId: id }), // Convert object to JSON string
            headers: {
              "Content-Type": "application/json", // Set the correct content type for JSON
            },
          }
        );
        if (response.ok) {
          window.location.reload();
        } else {
          console.error("Failed to submit property:", response.status);
        }
      } catch (error) {
        console.error("Error submitting property:", error);
      }
    }
  };
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const [showInstructions, setShowInstructions] = useState(false);
  const [address, setAddress] = useState([]);
  const availId = propertyId ? propertyId : id;

  const fetchData = async () => {
    try {
      const data = {
        userId: user ? user.id : "", // Send null if user.id is null
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_IP}/v2/cjebchu/website/ckijevcu/flatseeker/${availId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        const newdata = await response.json();
        const data = newdata.data;
        console.log(data);
        setProperty(data.property);
        setProperties(data.recommendedProperties);

        // Set up the address based on the response
        const address = {
          coordinates: [
            parseFloat(data.address && data.address.latitude) || 23.0225,
            parseFloat(data.address && data.address.logitude) || 72.5714,
          ],
          label: data.title,
          link: `/particular-property/${data.id}`,
        };

        setAddress([address]);
      } else {
        console.log("Error fetching data");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [properties, setProperties] = useState();
  console.log(properties);
  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [user, id]);

  if (!property) {
    return (
      <div className="text-center fs-4">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ); // You can show a loading message or spinner
  }
  if (!properties) {
    return (
      <div className="text-center fs-4">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ); // You can show a loading message or spinner
  }

  return (
    <div className="bg-lightblue">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <Downloads
        isOpen={downloadmodel}
        onClose={() => {
          setdownloadmodel(false);
        }}
      />

      <div className="container flatseeker-container py-4">
        <div id="property-heading" className="">
          <h2 id="addressHeading  " className="pertiflat-heading">
            {property.title}
            {/* {property.address.pincode} */}
          </h2>
          <div className=" col-lg-2 flex justify-content-around" id="IconGroup">
            <span
              className={`bookmark-button ShareIcons heart ${
                user && user.saved_property.includes(property.id)
                  ? "bookmarked"
                  : ""
              }`}
              onClick={downloadlink}
            >
              {!(user && user.saved_property.includes(property.id)) ? (
                <FontAwesomeIcon icon={faHeart} />
              ) : (
                <FontAwesomeIcon icon={fasolidHeart} />
              )}
            </span>
            <span className="ShareIcons">
              <FontAwesomeIcon
                icon={faShareFromSquare}
                style={{ zIndex: "1" }}
                onClick={handleShareClick}
              />
            </span>
            <span
              className="ShareIcons"
              onMouseEnter={() => handleMouseEnter(property.id)}
              onMouseLeave={handleMouseLeave}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </span>
            {showReportButtonForProperty === property.id && (
              <div
                className="reportBlock"
                onMouseEnter={() => handleMouseEnter(property.id)}
                onMouseLeave={handleMouseLeave}
              >
                <h6 className="text-center">Report Property </h6>
                <ul>
                  <li role="button" onClick={downloadlink}>
                    Rent Out
                  </li>
                  <li role="button" onClick={downloadlink}>
                    Broker's Post
                  </li>
                  <li role="button" onClick={downloadlink}>
                    Asking for Brokerage
                  </li>
                  <li role="button" onClick={downloadlink}>
                    Not Answering
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        <div id="propertyPhotos" className=" mobileAndTab-hide">
          <div
            className=" left-photo "
            style={{ maxHeight: "500px", width: "40%" }}
          >
            <img
              src={property.profilePhoto || ""}
              onClick={() => openImageViewer(0)}
              alt="photos"
              width={"100%"}
              height={"300px"}
            />
            <div className="col-md-4" style={{ width: "100%" }}>
              <div className="detailsBox mobileAndTab-hide">
                <div className="boxHeader"> Budget :</div>
                <div className="detailsBox ">
                  <div className="innerDetailsBox">
                    <div className="bg-white rounded-2 my-1">
                      <div className="row align-items-center budget-row">
                        <div className="col-7 pricingLabel "> Budget</div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail fw-bold col-4">
                          {" "}
                          {property.budget}
                        </div>
                      </div>
                    </div>

                    <div className="bg-white rounded-2 my-1">
                      <div className="row align-items-center budget-row">
                        <div className="col-7 pricingLabel"> No of Person</div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail fw-bold col-4">
                          {" "}
                          {property.noOfPerson}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detailsBox">
                <div className="boxHeader"> Contact Owner :</div>
                <div className="innerDetailsBox">
                  <div className="labelTextForDetail">Owner:</div>
                  <div className="fs-6">{property.name}</div>
                  <div className="d-flex flex-column">
                    <Button
                      className="rounded-pill"
                      id="contactOwner-pp"
                      onClick={downloadlink}
                    >
                      {user &&
                      user.contacted_property &&
                      user.contacted_property.includes(property.id) ? (
                        <a href={`tel:${property.phone}`}>
                          <FontAwesomeIcon icon={faPhone} /> {property.phone}
                        </a>
                      ) : (
                        "Contact Owner"
                      )}
                    </Button>
                    <Button
                      className="rounded-pill"
                      id="sendMessage-pp"
                      onClick={downloadlink}
                    >
                      Send Message
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isViewerOpen && (
            <ImageViewer
              src={[property.profilePhoto]}
              currentIndex={currentImage}
              onClose={closeImageViewer}
              disableScroll={true}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
                height: "90%",
                top: "10%",
              }}
              closeOnClickOutside={true}
            />
          )}

          <div className="right photo" style={{ width: "60%" }}>
            <div className="detailsBox mobileAndTab-hide">
              <div className="boxHeader"> Flatseeker Details :</div>
              <div className=" innerDetailsBox">
                <div className="col-md">
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Description:</div>
                    <div className="valueTextForDetail">
                      {property.description}{" "}
                    </div>
                  </div>
                </div>
                <div className="col-md flat-details">
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">
                      Property Preference :
                    </div>
                    <div className="valueTextForDetail bg-property">
                      <ul className="propert-preference">
                        <li>{property.flatType || property.type}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Furnishing :</div>
                    <div className="valueTextForDetail bg-property">
                      <ul
                        className="propert-preference"
                        style={{ listStyle: "none", paddingLeft: "0px" }}
                      >
                        <li>
                          {property.furnishedType[0] &&
                            property.furnishedType[0]}
                        </li>
                        {property.furnishedType[1] && (
                          <li>{property.furnishedType[1]}</li>
                        )}
                        {property.furnishedType[2] && (
                          <li>{property.furnishedType[2]}</li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Area</div>
                    <div className="valueTextForDetail bg-property">
                      {/* {property.availableFrom} */}
                      <ul
                        className="propert-preference"
                        style={{ listStyle: "none", paddingLeft: "0px" }}
                      >
                        {property.area.map((item, index) => {
                          return <li>{item}</li>;
                        })}
                      </ul>
                    </div>
                  </div>

                  {property.occupancyType != "" && (
                    <div className="SingleDetailBox">
                      <div className="labelTextForDetail">Occupancy Type</div>
                      <div className="valueTextForDetail bg-property">
                        {/* {property.availableFrom} */}
                        <ul
                          className="propert-preference"
                          style={{ listStyle: "none", paddingLeft: "0px" }}
                        >
                          {property.occupancyType.map((item, index) => {
                            return <li>{item}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Shifting Date</div>
                    <div className="valueTextForDetail">
                      {/* {property.availableFrom} */}
                      {property.shiftingDate}
                    </div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Gender</div>
                    <div className="valueTextForDetail bg-property">
                      <ul className="propert-preference">
                        <li>{property.gender}</li>
                      </ul>
                    </div>
                  </div>
                  {property.preference && (
                    <div className="SingleDetailBox">
                      <div className="labelTextForDetail">User Personality</div>
                      <div className="valueTextForDetail">
                        <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                          {property.preference.map((item, index) => {
                            return (
                              <li key={index}>
                                {item === "Health Conscious" && (
                                  <div className="AmenityBox ">
                                    <img
                                      className="amenities-size"
                                      src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-wellness-4049800.svg"
                                      alt="Amenities"
                                    />
                                    <span>Health Conscious</span>
                                  </div>
                                )}
                                {item === "Social Butterfly" && (
                                  <div className="AmenityBox ">
                                    <img
                                      className="amenities-size"
                                      src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-networking-138311.svg"
                                      alt="Amenities"
                                    />
                                    <span>Social Butterfly</span>
                                  </div>
                                )}
                                {item === "Pet Lover" && (
                                  <div className="AmenityBox ">
                                    <img
                                      className="amenities-size"
                                      src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-pet-lover-3347039.svg"
                                      alt="Amenities"
                                    />
                                    <span>Pet Lover</span>
                                  </div>
                                )}
                                {item === "Vegetarian" && (
                                  <div className="AmenityBox ">
                                    <img
                                      className="amenities-size"
                                      src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-leaves-1374829.svg"
                                      alt="Amenities"
                                    />
                                    <span>Vegetarian</span>
                                  </div>
                                )}
                                {item === "Night Owl" && (
                                  <div className="AmenityBox ">
                                    <img
                                      className="amenities-size"
                                      src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-night-time-5876451.svg"
                                      alt="Amenities"
                                    />
                                    <span>Night Owl</span>
                                  </div>
                                )}
                                {item === "Foodie" && (
                                  <div className="AmenityBox ">
                                    <img
                                      className="amenities-size"
                                      src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-pizza-2340181.svg"
                                      alt="Amenities"
                                    />
                                    <span>Foodie</span>
                                  </div>
                                )}
                                {item === "Early Riser" && (
                                  <div className="AmenityBox ">
                                    <img
                                      className="amenities-size"
                                      src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-sun-6612922.svg"
                                      alt="Amenities"
                                    />
                                    <span>Early Riser</span>
                                  </div>
                                )}
                                {item === "Alcohol Enthusiast" && (
                                  <div className="AmenityBox ">
                                    <img
                                      className="amenities-size"
                                      src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-beer-6403759.svg"
                                      alt="Amenities"
                                    />
                                    <span>Alcohol Enthusiast</span>
                                  </div>
                                )}
                                {item === "Smoking" && (
                                  <div className="AmenityBox c">
                                    <img
                                      className="amenities-size"
                                      src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-smoking-1473219.svg"
                                      alt="Amenities"
                                    />
                                    <span>Smoking</span>
                                  </div>
                                )}
                                {item === "Adventure Seeker" && (
                                  <div className="AmenityBox ">
                                    <img
                                      className="amenities-size"
                                      src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-trip-1650697.svg"
                                      alt="Amenities"
                                    />
                                    <span>Adventure Seeker</span>
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md"></div>
              </div>
            </div>

            <div className="flex border-b border-gray-300 LaptopHide">
              <TabSelector
                isActive={selectedTab === "details"}
                onClick={() => setSelectedTab("details")}
              >
                Details
              </TabSelector>

              <TabSelector
                isActive={selectedTab === "pricing"}
                onClick={() => setSelectedTab("pricing")}
              >
                Pricing
              </TabSelector>
            </div>
            <div className="LaptopHide">
              <TabPanel hidden={selectedTab !== "details"}>
                <div className="detailsBox">
                  <div className="row innerDetailsBox">
                    <div className="col-md">
                      <div className="SingleDetailBox row">
                        <div className="labelTextForDetail col-5">
                          Description
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail col-6">
                          {property.description}{" "}
                        </div>
                      </div>
                      <div className="SingleDetailBox row">
                        <div className="labelTextForDetail col-5">
                          Property Preference :
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail col-6">
                          {property.flatType || property.type}{" "}
                        </div>
                      </div>
                      <div className="SingleDetailBox row">
                        <div className="labelTextForDetail col-5">
                          Furnishing{" "}
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail col-6">
                          <ul>
                            <li>
                              {property.furnishedType[0] &&
                                property.furnishedType[0]}
                            </li>
                            {property.furnishedType[1] && (
                              <li>{property.furnishedType[1]}</li>
                            )}
                            {property.furnishedType[2] && (
                              <li>{property.furnishedType[2]}</li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="SingleDetailBox row">
                        <div className="labelTextForDetail col-5">
                          Available From
                        </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail col-6">
                          {property.shiftingDate}
                        </div>
                      </div>
                      <div className="SingleDetailBox row">
                        <div className="labelTextForDetail col-5">Gender </div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail col-6">
                          {property.gender}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="detailsBox ">
                  <div className="innerDetailsBox">
                    <div className="bg-white rounded-2 my-1">
                      <div className="row align-items-center budget-row">
                        <div className="col-7 pricingLabel "> Budget</div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail fw-bold col-4">
                          {property.budget}
                        </div>
                      </div>
                    </div>

                    <div className="bg-white rounded-2 my-1">
                      <div className="row align-items-center budget-row">
                        <div className="col-7 pricingLabel"> No of Person</div>
                        <div className="col-1">:</div>
                        <div className="valueTextForDetail fw-bold col-4">
                          {" "}
                          {property.noOfPerson}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>

        <div className="LaptopHide">
          <CustomCarousel photoUrls={[property.profilePhoto]} />
          <div id="iconBox" className="LaptopHide ">
            {/* <span
              className={`bookmark-button ShareIcons heart ${
                user &&
                user.saved_property &&
                user.saved_property.includes(property.id)
                  ? "bookmarked"
                  : ""
              }`}
              onClick={() => {
                toggleBookmark(property.id);
              }}
            > */}
            {/* {user &&
              user.saved_property &&
              user.saved_property.includes(property.id) ? (
                <FontAwesomeIcon icon={fasolidHeart} />
              ) : (
                <FontAwesomeIcon icon={faHeart} />
              )}
            </span> */}
            {/* <span
              className="ShareIcons"
              style={{ zIndex: "1" }}
              onClick={() => {
                handleShareClick(
                  property.title,
                  `/particular-property/${property.id}`
                );
              }}
            >
              <FontAwesomeIcon icon={faShareFromSquare} />
            </span> */}
            {/* <span
              className="ShareIcons"
              onClick={toggleReportBlock}
              style={{ zIndex: "1" }}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </span> */}
            {showReportBlock && (
              <div className="reportBlock">
                <h6 className="text-center d-flex justify-content-around">
                  Report Property{" "}
                  <FontAwesomeIcon onClick={toggleReportBlock} icon={faClose} />
                </h6>
                <ul>
                  <li
                    role="button"
                    onClick={() => {
                      reportProperty("Rent Out", property.id, property.ownerId);
                    }}
                  >
                    Rent Out
                  </li>
                  <li
                    role="button"
                    onClick={() => {
                      reportProperty(
                        "Broker's Post",
                        property.id,
                        property.ownerId
                      );
                    }}
                  >
                    Broker's Post
                  </li>
                  <li
                    role="button"
                    onClick={() => {
                      reportProperty(
                        "Asking for Brokerage",
                        property.id,
                        property.ownerId
                      );
                    }}
                  >
                    Asking for Brokerage
                  </li>
                  <li
                    role="button"
                    onClick={() => {
                      reportProperty(
                        "Not Answering",
                        property.id,
                        property.ownerId
                      );
                    }}
                  >
                    Not Answering
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="modal-controls">
          <div id="propertyPhotos" className=" mobileAndTab-hide">
            <div
              className=" left-photo "
              style={{ maxHeight: "500px", width: "40%" }}
            >
              <div className="col-md-4" style={{ width: "100%" }}>
                <div className="detailsBox mobileAndTab-hide">
                  <div className="boxHeader"> Budget :</div>
                  <div className="detailsBox ">
                    <div className="innerDetailsBox">
                      <div className="bg-white rounded-2 my-1">
                        <div className="row align-items-center budget-row">
                          <div className="col-7 pricingLabel "> Budget</div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail fw-bold col-4">
                            {" "}
                            {property.budget}
                          </div>
                        </div>
                      </div>

                      <div className="bg-white rounded-2 my-1">
                        <div className="row align-items-center budget-row">
                          <div className="col-7 pricingLabel">
                            {" "}
                            No of Person
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail fw-bold col-4">
                            {" "}
                            {property.noOfPerson}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailsBox">
                  <div className="boxHeader"> Contact Owner :</div>
                  <div className="innerDetailsBox">
                    <div className="labelTextForDetail">Owner:</div>
                    <div className="fs-6">{property.name}</div>
                    <div className="d-flex flex-column">
                      <Button
                        className="rounded-pill"
                        id="contactOwner-pp"
                        onClick={downloadlink}
                      >
                        {user &&
                        user.contacted_property &&
                        user.contacted_property.includes(property.id) ? (
                          <a href={`tel:${property.phone}`}>
                            <FontAwesomeIcon icon={faPhone} /> {property.phone}
                          </a>
                        ) : (
                          "Contact Owner"
                        )}
                      </Button>
                      <Button
                        className="rounded-pill"
                        id="sendMessage-pp"
                        onClick={downloadlink}
                      >
                        Send Message
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isViewerOpen && (
              <ImageViewer
                src={[property.profilePhoto]}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                disableScroll={true}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                  height: "90%",
                  top: "10%",
                }}
                closeOnClickOutside={true}
              />
            )}

            <div className="right photo" style={{ width: "60%" }}>
              <div className="detailsBox mobileAndTab-hide">
                <div className="boxHeader"> Flatseeker Details :</div>
                <div className=" innerDetailsBox">
                  <div className="col-md">
                    <div className="SingleDetailBox">
                      <div className="labelTextForDetail">Description:</div>
                      <div className="valueTextForDetail">
                        {property.description}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-md flat-details">
                    <div className="SingleDetailBox">
                      <div className="labelTextForDetail">
                        Property Preference :
                      </div>
                      <div className="valueTextForDetail bg-property">
                        <ul className="propert-preference">
                          <li>{property.flatType || property.type}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="SingleDetailBox">
                      <div className="labelTextForDetail">Furnishing :</div>
                      <div className="valueTextForDetail bg-property">
                        <ul
                          className="propert-preference"
                          style={{ listStyle: "none", paddingLeft: "0px" }}
                        >
                          <li>
                            {property.furnishedType[0] &&
                              property.furnishedType[0]}
                          </li>
                          {property.furnishedType[1] && (
                            <li>{property.furnishedType[1]}</li>
                          )}
                          {property.furnishedType[2] && (
                            <li>{property.furnishedType[2]}</li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="SingleDetailBox">
                      <div className="labelTextForDetail">Area</div>
                      <div className="valueTextForDetail bg-property">
                        {/* {property.availableFrom} */}
                        <ul
                          className="propert-preference"
                          style={{ listStyle: "none", paddingLeft: "0px" }}
                        >
                          {property.area.map((item, index) => {
                            return <li>{item}</li>;
                          })}
                        </ul>
                      </div>
                    </div>

                    {property.occupancyType != "" && (
                      <div className="SingleDetailBox">
                        <div className="labelTextForDetail">Occupancy Type</div>
                        <div className="valueTextForDetail bg-property">
                          {/* {property.availableFrom} */}
                          <ul
                            className="propert-preference"
                            style={{ listStyle: "none", paddingLeft: "0px" }}
                          >
                            {property.occupancyType.map((item, index) => {
                              return <li>{item}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                    <div className="SingleDetailBox">
                      <div className="labelTextForDetail">Shifting Date</div>
                      <div className="valueTextForDetail">
                        {/* {property.availableFrom} */}
                        {property.shiftingDate}
                      </div>
                    </div>
                    <div className="SingleDetailBox">
                      <div className="labelTextForDetail">Gender</div>
                      <div className="valueTextForDetail bg-property">
                        <ul className="propert-preference">
                          <li>{property.gender}</li>
                        </ul>
                      </div>
                    </div>
                    {property.preference && (
                      <div className="SingleDetailBox">
                        <div className="labelTextForDetail">
                          User Personality
                        </div>
                        <div className="valueTextForDetail">
                          <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                            {property.preference.map((item, index) => {
                              return (
                                <li key={index}>
                                  {item === "Health Conscious" && (
                                    <div className="AmenityBox ">
                                      <img
                                        className="amenities-size"
                                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-wellness-4049800.svg"
                                        alt="Amenities"
                                      />
                                      <span>Health Conscious</span>
                                    </div>
                                  )}
                                  {item === "Social Butterfly" && (
                                    <div className="AmenityBox ">
                                      <img
                                        className="amenities-size"
                                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-networking-138311.svg"
                                        alt="Amenities"
                                      />
                                      <span>Social Butterfly</span>
                                    </div>
                                  )}
                                  {item === "Pet Lover" && (
                                    <div className="AmenityBox ">
                                      <img
                                        className="amenities-size"
                                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-pet-lover-3347039.svg"
                                        alt="Amenities"
                                      />
                                      <span>Pet Lover</span>
                                    </div>
                                  )}
                                  {item === "Vegetarian" && (
                                    <div className="AmenityBox ">
                                      <img
                                        className="amenities-size"
                                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-leaves-1374829.svg"
                                        alt="Amenities"
                                      />
                                      <span>Vegetarian</span>
                                    </div>
                                  )}
                                  {item === "Night Owl" && (
                                    <div className="AmenityBox ">
                                      <img
                                        className="amenities-size"
                                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-night-time-5876451.svg"
                                        alt="Amenities"
                                      />
                                      <span>Night Owl</span>
                                    </div>
                                  )}
                                  {item === "Foodie" && (
                                    <div className="AmenityBox ">
                                      <img
                                        className="amenities-size"
                                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-pizza-2340181.svg"
                                        alt="Amenities"
                                      />
                                      <span>Foodie</span>
                                    </div>
                                  )}
                                  {item === "Early Riser" && (
                                    <div className="AmenityBox ">
                                      <img
                                        className="amenities-size"
                                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-sun-6612922.svg"
                                        alt="Amenities"
                                      />
                                      <span>Early Riser</span>
                                    </div>
                                  )}
                                  {item === "Alcohol Enthusiast" && (
                                    <div className="AmenityBox ">
                                      <img
                                        className="amenities-size"
                                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-beer-6403759.svg"
                                        alt="Amenities"
                                      />
                                      <span>Alcohol Enthusiast</span>
                                    </div>
                                  )}
                                  {item === "Smoking" && (
                                    <div className="AmenityBox ">
                                      <img
                                        className="amenities-size"
                                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-smoking-1473219.svg"
                                        alt="Amenities"
                                      />
                                      <span>Smoking</span>
                                    </div>
                                  )}
                                  {item === "Adventure Seeker" && (
                                    <div className="AmenityBox ">
                                      <img
                                        className="amenities-size"
                                        src="https://citynect1.s3.ap-south-1.amazonaws.com/preferences+icons/noun-trip-1650697.svg"
                                        alt="Amenities"
                                      />
                                      <span>Adventure Seeker</span>
                                    </div>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md"></div>
                </div>
              </div>

              <div className="flex border-b border-gray-300 LaptopHide">
                <TabSelector
                  isActive={selectedTab === "details"}
                  onClick={() => setSelectedTab("details")}
                >
                  Details
                </TabSelector>

                <TabSelector
                  isActive={selectedTab === "pricing"}
                  onClick={() => setSelectedTab("pricing")}
                >
                  Pricing
                </TabSelector>
              </div>
              <div className="LaptopHide">
                <TabPanel hidden={selectedTab !== "details"}>
                  <div className="detailsBox">
                    <div className="row innerDetailsBox">
                      <div className="col-md">
                        <div className="SingleDetailBox row">
                          <div className="labelTextForDetail col-5">
                            Description
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail col-6">
                            {property.description}{" "}
                          </div>
                        </div>
                        <div className="SingleDetailBox row">
                          <div className="labelTextForDetail col-5">
                            Property Preference :
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail col-6">
                            {property.flatType || property.type}{" "}
                          </div>
                        </div>
                        <div className="SingleDetailBox row">
                          <div className="labelTextForDetail col-5">
                            Furnishing{" "}
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail col-6">
                            <ul>
                              <li>
                                {property.furnishedType[0] &&
                                  property.furnishedType[0]}
                              </li>
                              {property.furnishedType[1] && (
                                <li>{property.furnishedType[1]}</li>
                              )}
                              {property.furnishedType[2] && (
                                <li>{property.furnishedType[2]}</li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="SingleDetailBox row">
                          <div className="labelTextForDetail col-5">
                            Available From
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail col-6">
                            {property.shiftingDate}
                          </div>
                        </div>
                        <div className="SingleDetailBox row">
                          <div className="labelTextForDetail col-5">
                            Gender{" "}
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail col-6">
                            {property.gender}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="detailsBox ">
                    <div className="innerDetailsBox">
                      <div className="bg-white rounded-2 my-1">
                        <div className="row align-items-center budget-row">
                          <div className="col-7 pricingLabel "> Budget</div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail fw-bold col-4">
                            {property.budget}
                          </div>
                        </div>
                      </div>

                      <div className="bg-white rounded-2 my-1">
                        <div className="row align-items-center budget-row">
                          <div className="col-7 pricingLabel">
                            {" "}
                            No of Person
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail fw-bold col-4">
                            {" "}
                            {property.noOfPerson}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>

          <div className="LaptopHide">
            <CustomCarousel photoUrls={[property.profilePhoto]} />
            <div id="iconBox" className="LaptopHide">
              <span
                className={`bookmark-button ShareIcons heart ${
                  user &&
                  user.saved_property &&
                  user.saved_property.includes(property.id)
                    ? "bookmarked"
                    : ""
                }`}
                onClick={() => {
                  toggleBookmark(property.id);
                }}
              >
                {user &&
                user.saved_property &&
                user.saved_property.includes(property.id) ? (
                  <FontAwesomeIcon icon={fasolidHeart} />
                ) : (
                  <FontAwesomeIcon icon={faHeart} />
                )}
              </span>
              <span
                className="ShareIcons"
                style={{ zIndex: "1" }}
                onClick={() => {
                  handleShareClick(
                    property.title,
                    `/particular-property/${property.id}`
                  );
                }}
              >
                <FontAwesomeIcon icon={faShareFromSquare} />
              </span>
              <span
                className="ShareIcons"
                onClick={toggleReportBlock}
                style={{ zIndex: "1" }}
              >
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </span>
              {showReportBlock && (
                <div className="reportBlock">
                  <h6 className="text-center d-flex justify-content-around">
                    Report Property{" "}
                    <FontAwesomeIcon
                      onClick={toggleReportBlock}
                      icon={faClose}
                    />
                  </h6>
                  <ul>
                    <li
                      role="button"
                      onClick={() => {
                        reportProperty(
                          "Rent Out",
                          property.id,
                          property.ownerId
                        );
                      }}
                    >
                      Rent Out
                    </li>
                    <li
                      role="button"
                      onClick={() => {
                        reportProperty(
                          "Broker's Post",
                          property.id,
                          property.ownerId
                        );
                      }}
                    >
                      Broker's Post
                    </li>
                    <li
                      role="button"
                      onClick={() => {
                        reportProperty(
                          "Asking for Brokerage",
                          property.id,
                          property.ownerId
                        );
                      }}
                    >
                      Asking for Brokerage
                    </li>
                    <li
                      role="button"
                      onClick={() => {
                        reportProperty(
                          "Not Answering",
                          property.id,
                          property.ownerId
                        );
                      }}
                    >
                      Not Answering
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div id="similarPropertyHeading">
          <ExpirePopup isOpen={expirePopupmodal} onClose={ExpirePopupModal} />
          <h3 className="mt-5">Similar Properties :</h3>
        </div>

        <div className="d-none d-md-block">
        <div className="featuresRow" >
          {properties.map((property, index) => {
            console.log(property);
            return (
              index < 5 && (
                <div className="card propertyCard " key={index}>
                  <Link
                    to={`/flatseekers?propertyId=${property.id}`}
                    className="text-decoration-none text-dark phone-width"
                  >
                    {property.videoLink ? (
                      <div className="video-thumbnail">
                        <video
                          width="300"
                          height="156"
                          controls
                          src={property.videoLink}
                          alt="video thumbnail"
                        />
                      </div>
                    ) : (
                      <Image
                        src={property.profilePhoto}
                        className="card-img-top"
                        width={"300px"}
                        height={"156px"}
                        alt="..."
                      />
                    )}
                    <div className="card-body" id="custom-title">
                      <h6 className="card-title">
                        <b>
                          {property.title.length > 70
                            ? `${property.title.substring(0, 70)}.`
                            : property.title}
                        </b>
                      </h6>
                      <p className="card-text">
                        <FontAwesomeIcon
                          className="me-2 grey"
                          icon={faLocationDot}
                        />{" "}
                        {property.area && property.area.length > 0 && (
                          <>
                            {property.area.slice(0, 2).join(", ")}
                            {property.area.length > 2 &&
                              ` +${property.area.length - 2}`}
                          </>
                        )}
                      </p>
                      <p className="card-text text-bold">
                        <b>Budget </b> : ₹{property.budget}
                      </p>
                      <div className="preferences-details similar-preferences-details">
                        <ul>
                          {property.preference
                            .slice(0, 3)
                            .map((item, index) => {
                              return <li key={index}>{item}</li>;
                            })}
                          {property.preference.length > 5 && (
                            <li
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              + {property.preference.length - 3}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            );
          })}
        </div>
        </div>
       
        <div className="d-md-none">
        <div className="featuresRow" >
          {properties.map((property, index) => {
            console.log(property);
            return (
              index < 5 && (
                <div className="card propertyCard " key={index}>
                  <Link
                    to={`/particular-Flatseeker/${property.id}`}
                    className="text-decoration-none text-dark phone-width"
                  >
                    {property.videoLink ? (
                      <div className="video-thumbnail">
                        <video
                          width="300"
                          height="156"
                          controls
                          src={property.videoLink}
                          alt="video thumbnail"
                        />
                      </div>
                    ) : (
                      <Image
                        src={property.profilePhoto}
                        className="card-img-top"
                        width={"300px"}
                        height={"156px"}
                        alt="..."
                      />
                    )}
                    <div className="card-body" id="custom-title">
                      <h6 className="card-title">
                        <b>
                          {property.title.length > 70
                            ? `${property.title.substring(0, 70)}.`
                            : property.title}
                        </b>
                      </h6>
                      <p className="card-text">
                        <FontAwesomeIcon
                          className="me-2 grey"
                          icon={faLocationDot}
                        />{" "}
                        {property.area && property.area.length > 0 && (
                          <>
                            {property.area.slice(0, 2).join(", ")}
                            {property.area.length > 2 &&
                              ` +${property.area.length - 2}`}
                          </>
                        )}
                      </p>
                      <p className="card-text text-bold">
                        <b>Budget </b> : ₹{property.budget}
                      </p>
                      <div className="preferences-details similar-preferences-details">
                        <ul>
                          {property.preference
                            .slice(0, 3)
                            .map((item, index) => {
                              return <li key={index}>{item}</li>;
                            })}
                          {property.preference.length > 5 && (
                            <li
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              + {property.preference.length - 3}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            );
          })}
        </div>
        </div>
        

        
       


      </div>
    </div>
  );
};

export default ParticularProperty;
